import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';

import Button from 'components/Button';
import Evidencia from 'services/Evidencia';
import SemFoto from 'assets/imgs/SEMFOTO.png';

import './styles.scss';
import jsonToFormData from 'helpers/jsonBuildFormData';
import getCampoObrigatorioClassName from 'helpers/campoObrigatorio';

const SearchLoja = ({ changeLoja, formData, campoObrigatorio = false }) => {
  const [show, setShow] = useState(false);
  const [filtro, setFiltro] = useState('');
  const [lojas, setLojas] = useState([]);
  const [lojaSelecionada, setLojaSelecionada] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    window.showLoader();
    const data = await Evidencia.GetListLoja(filtro);
    setLojas(data.value);
    setShow(true);
    window.hideLoader();
  };

  const changeNewLoja = () => {
    if (lojaSelecionada.lojaId) {
      changeLoja({ ...formData, lojaId: lojaSelecionada.lojaId, loja: lojaSelecionada });
    }
    handleClose();
  };

  const changeLojaImage = async (e) => {
    window.showLoader();
    e.preventDefault();
    if (e.target.files.length === 0) return;
    const file = e.target.files[0];

    let uploadLojaLogo = {
      evidenciaId: formData.evidenciaId,
      lojaId: formData.lojaId,
      file: file
    };

    const uploadLojaLogoFormData = jsonToFormData(uploadLojaLogo);
    const data = await Evidencia.PostUploadLojaLogo(uploadLojaLogoFormData);

    changeLoja({
      ...formData,
      loja: { ...formData.loja, urlLogo: data.value.url ? data.value.url : formData.loja.urlLogo }
    });

    window.hideLoader();
  };

  return (
    <>
      <div className="col-6">
        <label>Codigo ou Razão Social da Loja</label>
        <div className="input-group mb-3">
          <input
            type="text"
            placeholder="Digite ao menos 3 digitos para pesquisar."
            className={`form-control ${
              campoObrigatorio && getCampoObrigatorioClassName(formData.lojaId)
            }`}
            style={{ minHeight: '47.6px' }}
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
          />
          <button
            type="button"
            className={`btn btn-primary px-4 ${
              campoObrigatorio && getCampoObrigatorioClassName(formData.lojaId)
            }`}
            disabled={filtro.length + 1 <= 3}
            onClick={handleShow}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
      <div className="col-6">
        <div className={`col-12 mb-3`}>
          {formData.loja && (
            <div className="row border border-dark p-1">
              <div className="col-4 text-center">
                <img
                  src={formData.loja?.urlLogo ? formData.loja?.urlLogo : SemFoto}
                  alt={formData.loja?.nome ? formData.loja?.nome : '-'}
                  className="w-100"
                  style={{ maxWidth: '80px' }}
                />
              </div>
              <div className="col-8 d-flex flex-column align-items-center p-1">
                <div>Trocar logo</div>
                <>
                  <label
                    htmlFor="uploadLogo"
                    className="btn btn-primary px-4"
                    style={{ borderRadius: '20px' }}>
                    Faça upload do arquivo
                    <input
                      type="file"
                      className="input-file d-none"
                      id="uploadLogo"
                      name="uploadLogo"
                      onChange={(e) => changeLojaImage(e)}
                    />
                  </label>
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Modal  */}
      <Modal show={show} onHide={handleClose} className="rede-modal">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>Selecione a loja e clique em confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row border border-1 p-2 my-1 modal-rede-list">
            {/* {renderRedes()} */}
            {lojas.length
              ? lojas.map((item) => {
                  return (
                    <div
                      className={`col-12 ${
                        lojaSelecionada.lojaId === item.lojaId && 'item-selecionado'
                      }`}
                      key={item.lojaId}
                      onClick={() =>
                        setLojaSelecionada(lojaSelecionada.lojaId !== item.lojaId ? item : {})
                      }>
                      <div className="row py-2 rede-item">
                        <div className="col-4">
                          <img
                            src={item.urlLogo ? item.urlLogo : SemFoto}
                            alt={item.nome}
                            className="w-100"
                          />
                        </div>
                        <div className="col-8 d-flex justify-content-start align-items-center ps-4">
                          {item.nome}
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="ms-2 p-2 fw-semibold" width="320px" onClick={() => changeNewLoja()}>
            CONFIRMAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchLoja;
