import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FormBox from 'components/FormBox';
import PageTitle from 'components/PageTitle';

import Form from './form';
import jsonToFormData from 'helpers/jsonBuildFormData';
import Resume from './resume';

import EvidenciaAtivacaoEncarte from 'services/EvidenciaAtivacaoEncarte';
import { getDateFromStringJson } from 'helpers/Date';
import EvidenciaAtivacaoEncarteRede from 'services/EvidenciaAtivacaoEncarteRede';
import Rascunho from 'services/Rascunho';

function Encartes() {
  const navigate = useNavigate();
  const { id, rascunho } = useParams();

  const [activeTab, setActiveTab] = useState({
    pagina: 'form',
    subTitle: 'Preencha os dados abaixo e inclua as mídias:'
  });
  const resetInitialValueFormData = (tipoEncarte, formData) => {
    return {
      ...formData,
      tipoEncarte: tipoEncarte,
      inicio: '',
      fim: '',
      investimento: '',
      arquivos: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
      solicitacao: {
        solicitacaoId: null,
        razaoSocial: null,
        inicio: '',
        fim: '',
        custoTotal: ''
      },
      lojaId: '',
      loja: '',
      investimentoJBPRadio: false
    };
  };

  const [formData, setFormData] = useState(resetInitialValueFormData('exclusivo'));

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    (async () => {
      window.showLoader();
      if (!formData.evidenciaId) {
        //edit
        if (id > 0) {
          if (rascunho === 'rascunho') {
            let { value } = await Rascunho.GetRascunho(id);
            value = JSON.parse(value.rascunho);
            if (value.tipoEncarte === 'exclusivo') {
              const { solicitacao } = value;
              setFormData({
                ...formData,
                ...value,
                inicio: getDateFromStringJson(value.inicio),
                fim: getDateFromStringJson(value.fim),
                arquivos: formData.arquivos.map((arquivo, index) => ({
                  ...arquivo,
                  ...value.arquivos[index]
                })),
                solicitacao: {
                  ...solicitacao,
                  inicio: getDateFromStringJson(solicitacao.inicio),
                  fim: getDateFromStringJson(solicitacao.fim)
                },
                investimentoJBPRadio: Boolean(value.investimento === -1)
              });
            } else if (value.tipoEncarte === 'rede') {
              setFormData({
                ...formData,
                ...value,
                inicio: getDateFromStringJson(value.inicio),
                fim: getDateFromStringJson(value.fim),
                arquivos: formData.arquivos.map((arquivo, index) => ({
                  ...arquivo,
                  ...value.arquivos[index]
                })),
                loja: value.loja,
                investimentoJBPRadio: Boolean(value.investimento === -1)
              });
            }
          } else {
            const { value } = await EvidenciaAtivacaoEncarte.edit(id);
            const { value: valueRede } = await EvidenciaAtivacaoEncarteRede.edit(id);
            if (value.tipoEncarte === 'exclusivo') {
              const { solicitacao } = value;
              setFormData({
                ...formData,
                ...value,
                inicio: getDateFromStringJson(value.inicio),
                fim: getDateFromStringJson(value.fim),
                arquivos: formData.arquivos.map((arquivo, index) => ({
                  ...arquivo,
                  ...value.arquivos[index]
                })),
                solicitacao: {
                  ...solicitacao,
                  inicio: getDateFromStringJson(solicitacao.inicio),
                  fim: getDateFromStringJson(solicitacao.fim)
                },
                investimentoJBPRadio: Boolean(value.investimento === -1)
              });
            } else if (valueRede.tipoEncarte === 'rede') {
              setFormData({
                ...formData,
                ...valueRede,
                inicio: getDateFromStringJson(valueRede.inicio),
                fim: getDateFromStringJson(valueRede.fim),
                arquivos: formData.arquivos.map((arquivo, index) => ({
                  ...arquivo,
                  ...valueRede.arquivos[index]
                })),
                loja: valueRede.loja,
                investimentoJBPRadio: Boolean(value.investimento === -1)
              });
            }
          }
        }
        //create
        else {
          const { value } = await EvidenciaAtivacaoEncarte.create();
          setFormData({ ...formData, evidenciaId: value.evidenciaId });
        }
      }
      window.hideLoader();
    })();
  }, []);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const saveForm = async () => {
    window.showLoader();
    const arquivos = formData.arquivos.filter(
      (item) => item.file || item.urlArquivo?.length > 0 || item.urlS3?.length > 0
    );

    let data;
    if (formData.tipoEncarte === 'exclusivo') {
      const jsonData = {
        arquivos: arquivos,
        tipoEncarte: formData.tipoEncarte,
        evidenciaId: formData.evidenciaId,
        inicio: formData.inicio,
        fim: formData.fim,
        investimento: formData.investimento,
        solicitacaoId: formData.solicitacao?.solicitacaoId
      };

      const formRequest = jsonToFormData(jsonData);
      data = await EvidenciaAtivacaoEncarte.save(formRequest);
    }
    if (formData.tipoEncarte === 'rede') {
      const jsonData = {
        arquivos: arquivos,
        tipoEncarte: formData.tipoEncarte,
        evidenciaId: formData.evidenciaId,
        inicio: formData.inicio,
        fim: formData.fim,
        investimento: formData.investimento,
        lojaId: formData.lojaId
      };
      const formRequest = jsonToFormData(jsonData);
      data = await EvidenciaAtivacaoEncarteRede.save(formRequest);
    }

    if (data?.value) {
      window.hideLoader();
      navigate('/sucesso');
    } else {
      window.hideLoader();
      window.scrollTo(0, 0);
      setErrors(data?.errors);
    }
  };

  const renderTab = () => {
    let contentNow = <h1>Página não encontrada</h1>;
    if (activeTab.pagina === 'form') {
      contentNow = (
        <Form
          formData={formData}
          setFormData={setFormData}
          nextTab={() => changeTab({ pagina: 'resume', subTitle: 'Confira todos os dados abaixo' })}
          resetFormDataValue={resetInitialValueFormData}
        />
      );
    }
    if (activeTab.pagina === 'resume') {
      contentNow = (
        <Resume
          backTab={() =>
            changeTab({ pagina: 'form', subTitle: 'Preencha os dados abaixo e inclua as mídias:' })
          }
          saveForm={saveForm}
          formData={formData}
        />
      );
    }
    return contentNow;
  };

  return (
    <div className="aticacaoEncartes">
      <PageTitle subTitle="Preencha os dados abaixo e inclua as mídias:">
        ATIVAÇÕES - ENCARTE
      </PageTitle>
      <FormBox>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            Erro ao realizar o envio, por favor confira os dados e tente novamente.
          </div>
        )}

        <div className="row">{renderTab()}</div>
      </FormBox>
    </div>
  );
}

export default Encartes;
