import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { flushSync } from 'react-dom';

import BackButton from 'components/BackButton';
import MyDatePicker from 'components/DatePicker';
import InputCurrency from 'components/InputCurrency';
import SaveButton from 'components/SaveButton';
import SearchRede from 'components/SearchRede';
import Button from 'components/Button';
import RadioBox from 'components/RadioBox';
import SelectMult from 'components/SelectMult';
import Input from 'components/Input';
import UploadImage from 'components/UploadImage';

import './form.scss';
import TimerRascunho from 'components/TimerRascunho';

const Form = ({
  formData,
  setFormData,
  nextTab,
  // mpdvList,
  marcaList,
  categoriasPositivasList,
  construtorPontoNatural,
  construtorPontoExtra
}) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }
  }, [formData]);

  const [show, setShow] = useState(false);
  const [itemDelete, setItemDelete] = useState({
    item: '',
    tipo: ''
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const listRefPontoExtra = useRef(null);
  const listRefPontoNatural = useRef(null);

  const validacaoFormulario = async () => {
    let todosErros = [];

    !formData.redeId && todosErros.push('Rede');

    if (!formData.pontoNatural.length && !formData.pontoExtra.length) {
      todosErros.push('Ponto Natural ou Ponto Extra');
    }

    if (!formData.pontoNatural.length && !formData.pontoExtra.length) {
      todosErros.push('Ponto Natural ou Ponto Extra');
    }

    if (formData.pontoExtra.length) {
      formData.pontoExtra.map((item) => {
        !item.inicio && todosErros.push(`PONTO EXTRA ${item.id} - Data Inicial`);
        !item.fim && todosErros.push(`PONTO EXTRA ${item.id} - Data Final`);
        item.inicio > item.fim &&
          todosErros.push(`PONTO EXTRA ${item.id} - Data inicial superio a data final`);
        !item.investimento && todosErros.push(`PONTO EXTRA ${item.id} - Investimento na Ação`);
        !item.qtdeLojasAtivadas && todosErros.push(`PONTO EXTRA ${item.id} - Qtd. Lojas Ativadas`);
        !item.marcasPositivadas.length &&
          item.marcaPositivadaRadio &&
          todosErros.push(`PONTO EXTRA ${item.id} - Marca Positivada Utilizada`);
        !item.categoriasPositivadas.length &&
          item.categoriasPositivadaRadio &&
          todosErros.push(`PONTO EXTRA ${item.id} - Categ. Positivada Utilizado`);
        const arquivosWithFile = item.arquivos.filter((arq) => arq.file || arq.urlArquivo);
        !arquivosWithFile.length && todosErros.push(`PONTO EXTRA ${item.id} - Arquivos`);
      });
    }

    if (formData.pontoNatural.length) {
      formData.pontoNatural.map((item) => {
        // Preencha ao menos uma categoria
        if (
          !item.spreadsInvestimento &&
          !item.spreadsInicio &&
          !item.spreadsFim &&
          !item.spreadsQtdeLojasAtivadas &&
          !item.spreadsQtdeEspacoNegociado &&
          !item.spreadsQtdeEspacoExecutado &&
          !item.spreadsMarcasPositivadas.length &&
          !item.spreadsEvidenciaArquivo.file &&
          !item.friosInvestimento &&
          !item.friosInicio &&
          !item.friosFim &&
          !item.friosQtdeLojasAtivadas &&
          !item.friosQtdeEspacoNegociado &&
          !item.friosQtdeEspacoExecutado &&
          !item.friosMarcasPositivadas.length &&
          !item.friosEvidenciaArquivo.file &&
          !item.congeladosInvestimento &&
          !item.congeladosInicio &&
          !item.congeladosFim &&
          !item.congeladosQtdeLojasAtivadas &&
          !item.congeladosQtdeEspacoNegociado &&
          !item.congeladosQtdeEspacoExecutado &&
          !item.congeladosMarcasPositivadas.length &&
          !item.congeladosEvidenciaArquivo.file &&
          !item.embutidosInvestimento &&
          !item.embutidosInicio &&
          !item.embutidosFim &&
          !item.embutidosQtdeLojasAtivadas &&
          !item.embutidosQtdeEspacoNegociado &&
          !item.embutidosQtdeEspacoExecutado &&
          !item.embutidosMarcasPositivadas.length &&
          !item.embutidosEvidenciaArquivo.file &&
          !item.inNaturaInvestimento &&
          !item.inNaturaInicio &&
          !item.inNaturaFim &&
          !item.inNaturaQtdeLojasAtivadas &&
          !item.inNaturaQtdeEspacoNegociado &&
          !item.inNaturaQtdeEspacoExecutado &&
          !item.inNaturaMarcasPositivadas.length &&
          (!item.inNaturaEvidenciaArquivo.file || item.inNaturaEvidenciaArquivo.urlArquivo)
        ) {
          todosErros.push(`PONTO NATURAL ${item.id}`);
        }

        // SPREADS
        if (
          item.spreadsInvestimento === 0 ||
          item.spreadsInicio ||
          item.spreadsFim ||
          item.spreadsQtdeLojasAtivadas ||
          item.spreadsQtdeEspacoNegociado ||
          item.spreadsQtdeEspacoExecutado ||
          item.spreadsMarcasPositivadas.length ||
          item.spreadsEvidenciaArquivo.file ||
          item.spreadsEvidenciaArquivo.urlArquivo
        ) {
          !item.spreadsInvestimento &&
            todosErros.push(`PONTO NATURAL ${item.id} - SPREADS Investimento na Ação`);
          !item.spreadsInicio && todosErros.push(`PONTO NATURAL ${item.id} - SPREADS Data Inicial`);
          !item.spreadsFim && todosErros.push(`PONTO NATURAL ${item.id} - SPREADS Data Final`);
          item.inicio > item.fim &&
            todosErros.push(`PONTO NATURAL ${item.id} - SPREADS Data inicial superio a data final`);
          !item.spreadsQtdeLojasAtivadas &&
            todosErros.push(`PONTO NATURAL ${item.id} - SPREADS Qtd. Lojas Ativadas`);
          !item.spreadsQtdeEspacoNegociado &&
            todosErros.push(`PONTO NATURAL ${item.id} - SPREADS Qtd. Espaço Negociado`);
          !item.spreadsQtdeEspacoExecutado &&
            todosErros.push(`PONTO NATURAL ${item.id} - SPREADS Qtd. Espaço Executado`);
          !item.spreadsMarcasPositivadas.length &&
            todosErros.push(`PONTO NATURAL ${item.id} - SPREADS Marca Positivada`);
          if (
            !Object.hasOwn(item.spreadsEvidenciaArquivo, 'file') &&
            !Object.hasOwn(item.spreadsEvidenciaArquivo, 'urlArquivo')
          ) {
            todosErros.push(`PONTO NATURAL ${item.id} - SPREADS Arquivos`);
          }
        }
        // FRIOS
        if (
          item.friosInvestimento === 0 ||
          item.friosInicio ||
          item.friosFim ||
          item.friosQtdeLojasAtivadas ||
          item.friosQtdeEspacoNegociado ||
          item.friosQtdeEspacoExecutado ||
          item.friosMarcasPositivadas.length ||
          item.friosEvidenciaArquivo.file ||
          item.friosEvidenciaArquivo.urlArquivo
        ) {
          !item.friosInvestimento &&
            todosErros.push(`PONTO NATURAL ${item.id} - FRIOS Investimento na Ação`);
          !item.friosInicio && todosErros.push(`PONTO NATURAL ${item.id} - FRIOS Data Inicial`);
          !item.friosFim && todosErros.push(`PONTO NATURAL ${item.id} - FRIOS Data Final`);
          item.friosInicio > item.friosFim &&
            todosErros.push(`PONTO NATURAL ${item.id} - FRIOS Data inicial superio a data final`);
          !item.friosQtdeLojasAtivadas &&
            todosErros.push(`PONTO NATURAL ${item.id} - FRIOS Qtd. Lojas Ativadas`);
          !item.friosQtdeEspacoNegociado &&
            todosErros.push(`PONTO NATURAL ${item.id} - FRIOS Qtd. Espaço Negociado`);
          !item.friosQtdeEspacoExecutado &&
            todosErros.push(`PONTO NATURAL ${item.id} - FRIOS Qtd. Espaço Executado`);
          !item.friosMarcasPositivadas.length &&
            todosErros.push(`PONTO NATURAL ${item.id} - FRIOS Marca Positivada`);
          if (
            !Object.hasOwn(item.friosEvidenciaArquivo, 'file') &&
            !Object.hasOwn(item.friosEvidenciaArquivo, 'urlArquivo')
          ) {
            todosErros.push(`PONTO NATURAL ${item.id} - FRIOS Arquivos`);
          }
        }
        // CONGELADOS
        if (
          item.congeladosInvestimento === 0 ||
          item.congeladosInicio ||
          item.congeladosFim ||
          item.congeladosQtdeLojasAtivadas ||
          item.congeladosQtdeEspacoNegociado ||
          item.congeladosQtdeEspacoExecutado ||
          item.congeladosMarcasPositivadas.length ||
          item.congeladosEvidenciaArquivo.file ||
          item.congeladosEvidenciaArquivo.urlArquivo
        ) {
          !item.congeladosInvestimento &&
            todosErros.push(`PONTO NATURAL ${item.id} - CONGELADOS Investimento na Ação`);
          !item.congeladosInicio &&
            todosErros.push(`PONTO NATURAL ${item.id} - CONGELADOS Data Inicial`);
          !item.congeladosFim &&
            todosErros.push(`PONTO NATURAL ${item.id} - CONGELADOS Data Final`);
          item.congeladosInicio > item.congeladosFim &&
            todosErros.push(
              `PONTO NATURAL ${item.id} - CONGELADOS Data inicial superio a data final`
            );
          !item.congeladosQtdeLojasAtivadas &&
            todosErros.push(`PONTO NATURAL ${item.id} - CONGELADOS Qtd. Lojas Ativadas`);
          !item.congeladosQtdeEspacoNegociado &&
            todosErros.push(`PONTO NATURAL ${item.id} - CONGELADOS Qtd. Espaço Negociado`);
          !item.congeladosQtdeEspacoExecutado &&
            todosErros.push(`PONTO NATURAL ${item.id} - CONGELADOS Qtd. Espaço Executado`);
          !item.congeladosMarcasPositivadas.length &&
            todosErros.push(`PONTO NATURAL ${item.id} - CONGELADOS Marca Positivada`);
          if (
            !Object.hasOwn(item.congeladosEvidenciaArquivo, 'file') &&
            !Object.hasOwn(item.congeladosEvidenciaArquivo, 'urlArquivo')
          ) {
            todosErros.push(`PONTO NATURAL ${item.id} - CONGELADOS Arquivos`);
          }
        }
        // EMBUTIDOS
        if (
          item.embutidosInvestimento === 0 ||
          item.embutidosInicio ||
          item.embutidosFim ||
          item.embutidosQtdeLojasAtivadas ||
          item.embutidosQtdeEspacoNegociado ||
          item.embutidosQtdeEspacoExecutado ||
          item.embutidosMarcasPositivadas.length ||
          item.embutidosEvidenciaArquivo.file ||
          item.embutidosEvidenciaArquivo.urlArquivo
        ) {
          !item.embutidosInvestimento &&
            todosErros.push(`PONTO NATURAL ${item.id} - EMBUTIDOS Investimento na Ação`);
          !item.embutidosInicio &&
            todosErros.push(`PONTO NATURAL ${item.id} - EMBUTIDOS Data Inicial`);
          !item.embutidosFim && todosErros.push(`PONTO NATURAL ${item.id} - EMBUTIDOS Data Final`);
          item.embutidosInicio > item.embutidosFim &&
            todosErros.push(
              `PONTO NATURAL ${item.id} - EMBUTIDOS Data inicial superio a data final`
            );
          !item.embutidosQtdeLojasAtivadas &&
            todosErros.push(`PONTO NATURAL ${item.id} - EMBUTIDOS Qtd. Lojas Ativadas`);
          !item.embutidosQtdeEspacoNegociado &&
            todosErros.push(`PONTO NATURAL ${item.id} - EMBUTIDOS Qtd. Espaço Negociado`);
          !item.embutidosQtdeEspacoExecutado &&
            todosErros.push(`PONTO NATURAL ${item.id} - EMBUTIDOS Qtd. Espaço Executado`);
          !item.embutidosMarcasPositivadas.length &&
            todosErros.push(`PONTO NATURAL ${item.id} - EMBUTIDOS Marca Positivada`);
          if (
            !Object.hasOwn(item.embutidosEvidenciaArquivo, 'file') &&
            !Object.hasOwn(item.embutidosEvidenciaArquivo, 'urlArquivo')
          ) {
            todosErros.push(`PONTO NATURAL ${item.id} - EMBUTIDOS Arquivos`);
          }
        }
        // IN NATURA
        if (
          item.inNaturaInvestimento === 0 ||
          item.inNaturaInicio ||
          item.inNaturaFim ||
          item.inNaturaQtdeLojasAtivadas ||
          item.inNaturaQtdeEspacoNegociado ||
          item.inNaturaQtdeEspacoExecutado ||
          item.inNaturaMarcasPositivadas.length ||
          item.inNaturaEvidenciaArquivo.file ||
          item.inNaturaEvidenciaArquivo.urlArquivo
        ) {
          !item.inNaturaInvestimento &&
            todosErros.push(`PONTO NATURAL ${item.id} - IN NATURA Investimento na Ação`);
          !item.inNaturaInicio &&
            todosErros.push(`PONTO NATURAL ${item.id} - IN NATURA Data Inicial`);
          !item.inNaturaFim && todosErros.push(`PONTO NATURAL ${item.id} - IN NATURA Data Final`);
          item.inNaturaInicio > item.inNaturaFim &&
            todosErros.push(
              `PONTO NATURAL ${item.id} - IN NATURA Data inicial superio a data final`
            );
          !item.inNaturaQtdeLojasAtivadas &&
            todosErros.push(`PONTO NATURAL ${item.id} - IN NATURA Qtd. Lojas Ativadas`);
          !item.inNaturaQtdeEspacoNegociado &&
            todosErros.push(`PONTO NATURAL ${item.id} - IN NATURA Qtd. Espaço Negociado`);
          !item.inNaturaQtdeEspacoExecutado &&
            todosErros.push(`PONTO NATURAL ${item.id} - IN NATURA Qtd. Espaço Executado`);
          !item.inNaturaMarcasPositivadas.length &&
            todosErros.push(`PONTO NATURAL ${item.id} - IN NATURA Marca Positivada`);
          if (
            !Object.hasOwn(item.inNaturaEvidenciaArquivo, 'file') &&
            !Object.hasOwn(item.inNaturaEvidenciaArquivo, 'urlArquivo')
          ) {
            todosErros.push(`PONTO NATURAL ${item.id} - IN NATURA Arquivos`);
          }
        }
      });
    }

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const removeItemModal = async (ponto, tipo) => {
    if (tipo === 'ponto-natural') {
      let data = formData.pontoNatural.filter((item) => item.id !== ponto.id);
      data = data.map((item, index) => {
        return { ...item, id: index + 1 };
      });
      setFormData({
        ...formData,
        pontoNatural: data
      });
    } else if (tipo === 'ponto-extra') {
      let data = formData.pontoExtra.filter((item) => item.id !== ponto.id);
      data = data.map((item, index) => {
        return { ...item, id: index + 1 };
      });
      setFormData({
        ...formData,
        pontoExtra: data
      });
    }

    setItemDelete({
      item: '',
      tipo: ''
    });
    handleClose();
  };

  return (
    <div className="form-espaco">
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo <b>{value}</b> é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>

      <form className="row">
        <SearchRede changeRede={setFormData} formData={formData} campoObrigatorio />

        <div className="row">
          <div className="col-4">
            <RadioBox
              label={`A ativação está contemplada no JBP?`}
              name="investimentoRadio"
              selected={formData.investimentoJBPRadio}
              options={[
                { label: 'SIM', value: true },
                { label: 'NÃO', value: false }
              ]}
              onChange={(e) => {
                const newPontoExtra = formData.pontoExtra.length
                  ? formData.pontoExtra.map((item) => {
                      return {
                        ...item,
                        investimento: e === true ? -1 : 0
                      };
                    })
                  : [];

                const newPontoNatural = formData.pontoNatural.length
                  ? formData.pontoNatural.map((item) => {
                      return {
                        ...item,
                        spreadsInvestimento: e === true ? -1 : 0,
                        friosInvestimento: e === true ? -1 : 0,
                        congeladosInvestimento: e === true ? -1 : 0,
                        embutidosInvestimento: e === true ? -1 : 0,
                        inNaturaInvestimento: e === true ? -1 : 0
                      };
                    })
                  : [];
                setFormData({
                  ...formData,
                  investimentoJBPRadio: e,
                  pontoExtra: newPontoExtra,
                  pontoNatural: newPontoNatural
                });
              }}
            />
          </div>
        </div>

        <div className="container-pontos">
          <div className="pontoNatural" ref={listRefPontoNatural}>
            {formData.pontoNatural && formData.pontoNatural.length
              ? formData.pontoNatural.map((pontoNatural) => {
                  return (
                    <>
                      <Accordion
                        defaultActiveKey={`ponto-extra-${pontoNatural.id}`}
                        alwaysOpen
                        key={`ponto-extra-${pontoNatural.id}`}
                        className="ponto-extra mb-4">
                        <Accordion.Item eventKey={`ponto-extra-${pontoNatural.id}`}>
                          <Accordion.Header>
                            <h2 className="text-danger font-weight-bold">
                              PONTO NATURAL {pontoNatural.id}
                            </h2>
                          </Accordion.Header>
                          <Accordion.Body className="row">
                            <div>
                              <span className="float-end">
                                <button
                                  type="button"
                                  className={`btn btn-danger`}
                                  style={{
                                    borderRadius: '20px'
                                  }}
                                  // onClick={() => removeItemModal(pontoNatural, 'ponto-natural')}
                                  onClick={() => {
                                    handleShow();
                                    setItemDelete({
                                      item: pontoNatural,
                                      tipo: 'ponto-natural'
                                    });
                                  }}>
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </button>
                              </span>
                            </div>
                            <div className="col-4 mb-5">
                              <h5>SPREADS</h5>
                              {pontoNatural.spreadsInvestimento !== -1 && (
                                <InputCurrency
                                  label="Investimento Total"
                                  placeholder="2000"
                                  value={pontoNatural.spreadsInvestimento}
                                  onChange={(value) => {
                                    const newPontoNatural = formData.pontoNatural.map((item) =>
                                      item.id === pontoNatural.id
                                        ? { ...item, spreadsInvestimento: value }
                                        : item
                                    );
                                    setFormData({ ...formData, pontoNatural: newPontoNatural });
                                  }}
                                />
                              )}
                              <label className="form-label">Período Ativado</label>
                              <MyDatePicker
                                placeholderText="Data Inicial"
                                selected={pontoNatural.spreadsInicio}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, spreadsInicio: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <MyDatePicker
                                placeholderText="Data Final"
                                minDate={pontoNatural.spreadsInicio}
                                disabled={!pontoNatural.spreadsInicio}
                                selected={pontoNatural.spreadsFim}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, spreadsFim: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeLojasAtivadas"
                                type="number"
                                min="0"
                                label="Qtd. Lojas Ativadas"
                                value={pontoNatural.spreadsQtdeLojasAtivadas}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          spreadsQtdeLojasAtivadas: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoNegociado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Negociado"
                                value={pontoNatural.spreadsQtdeEspacoNegociado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          spreadsQtdeEspacoNegociado: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoExecutado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Executado"
                                value={pontoNatural.spreadsQtdeEspacoExecutado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          spreadsQtdeEspacoExecutado: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <SelectMult
                                name="marcaPositivadas"
                                label="Qual marca foi positivada?"
                                options={marcaList.map((item) => {
                                  return { value: item.produtoMarcaId, label: item.nome };
                                })}
                                placeholder="Seleção de até 5"
                                value={pontoNatural.spreadsMarcasPositivadas}
                                maxItem={5}
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          spreadsMarcasPositivadas:
                                            value.length <= 5
                                              ? value
                                              : item.spreadsMarcasPositivadas
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoExtra });
                                }}
                              />
                              <UploadImage
                                arquivos={pontoNatural.spreadsEvidenciaArquivo}
                                fullWidth
                                evidenciaId={formData.evidenciaId}
                                onChangeFile={(file) => {
                                  window.showLoader();
                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      return {
                                        ...item,
                                        spreadsEvidenciaArquivo: {
                                          ...item.spreadsEvidenciaArquivo,
                                          urlArquivo: file
                                        }
                                      };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({ ...formData, pontoNatural: pontoNaturalEdit });

                                  window.hideLoader();
                                }}
                                removeFile={(id) => {
                                  window.showLoader();
                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      // const arquivosEdit = item.spreadsEvidenciaArquivo.map(
                                      //   (arquivo) => {
                                      //     if (arquivo.id === id) {
                                      //       return { id: arquivo.id };
                                      //     } else {
                                      //       return arquivo;
                                      //     }
                                      //   }
                                      // );
                                      return { ...item, spreadsEvidenciaArquivo: { id: id } };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({
                                    ...formData,
                                    pontoNatural: pontoNaturalEdit
                                  });
                                  window.hideLoader();
                                }}
                              />
                            </div>
                            <div className="col-4 mb-5">
                              <h5>FRIOS</h5>
                              {pontoNatural.friosInvestimento !== -1 && (
                                <InputCurrency
                                  label="Investimento Total"
                                  placeholder="2000"
                                  value={pontoNatural.friosInvestimento}
                                  onChange={(value) => {
                                    const newPontoNatural = formData.pontoNatural.map((item) =>
                                      item.id === pontoNatural.id
                                        ? { ...item, friosInvestimento: value }
                                        : item
                                    );
                                    setFormData({ ...formData, pontoNatural: newPontoNatural });
                                  }}
                                />
                              )}
                              <label className="form-label">Período Ativado</label>
                              <MyDatePicker
                                placeholderText="Data Inicial"
                                selected={pontoNatural.friosInicio}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, friosInicio: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <MyDatePicker
                                placeholderText="Data Final"
                                minDate={pontoNatural.friosInicio}
                                disabled={!pontoNatural.friosInicio}
                                selected={pontoNatural.friosFim}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, friosFim: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeLojasAtivadas"
                                type="number"
                                label="Qtd. Lojas Ativadas"
                                min="0"
                                value={pontoNatural.friosQtdeLojasAtivadas}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          friosQtdeLojasAtivadas: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoNegociado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Negociado"
                                value={pontoNatural.friosQtdeEspacoNegociado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          friosQtdeEspacoNegociado: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoExecutado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Executado"
                                value={pontoNatural.friosQtdeEspacoExecutado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, friosQtdeEspacoExecutado: e.target.value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <SelectMult
                                name="marcaPositivadas"
                                label="Qual marca foi positivada?"
                                options={marcaList.map((item) => {
                                  return { value: item.produtoMarcaId, label: item.nome };
                                })}
                                placeholder="Seleção de até 5"
                                value={pontoNatural.friosMarcasPositivadas}
                                maxItem={5}
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          friosMarcasPositivadas:
                                            value.length <= 5 ? value : item.friosMarcasPositivadas
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoExtra });
                                }}
                              />
                              <UploadImage
                                arquivos={pontoNatural.friosEvidenciaArquivo}
                                fullWidth
                                evidenciaId={formData.evidenciaId}
                                onChangeFile={(file) => {
                                  window.showLoader();
                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      return {
                                        ...item,
                                        friosEvidenciaArquivo: {
                                          ...item.friosEvidenciaArquivo,
                                          urlArquivo: file
                                        }
                                      };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({ ...formData, pontoNatural: pontoNaturalEdit });
                                  window.hideLoader();
                                }}
                                removeFile={(id) => {
                                  window.showLoader();
                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      // const arquivosEdit = item.friosEvidenciaArquivo.map(
                                      //   (arquivo) => {
                                      //     if (arquivo.id === id) {
                                      //       return { id: arquivo.id };
                                      //     } else {
                                      //       return arquivo;
                                      //     }
                                      //   }
                                      // );
                                      return { ...item, friosEvidenciaArquivo: { id: id } };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({
                                    ...formData,
                                    pontoNatural: pontoNaturalEdit
                                  });
                                  window.hideLoader();
                                }}
                              />
                            </div>
                            <div className="col-4 mb-5">
                              <h5>CONGELADOS</h5>
                              {pontoNatural.congeladosInvestimento !== -1 && (
                                <InputCurrency
                                  label="Investimento Total"
                                  placeholder="2000"
                                  value={pontoNatural.congeladosInvestimento}
                                  onChange={(value) => {
                                    const newPontoNatural = formData.pontoNatural.map((item) =>
                                      item.id === pontoNatural.id
                                        ? { ...item, congeladosInvestimento: value }
                                        : item
                                    );
                                    setFormData({ ...formData, pontoNatural: newPontoNatural });
                                  }}
                                />
                              )}
                              <label className="form-label">Período Ativado</label>
                              <MyDatePicker
                                placeholderText="Data Inicial"
                                selected={pontoNatural.congeladosInicio}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, congeladosInicio: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <MyDatePicker
                                placeholderText="Data Final"
                                minDate={pontoNatural.congeladosInicio}
                                disabled={!pontoNatural.congeladosInicio}
                                selected={pontoNatural.congeladosFim}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, congeladosFim: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeLojasAtivadas"
                                type="number"
                                min="0"
                                label="Qtd. Lojas Ativadas"
                                value={pontoNatural.congeladosQtdeLojasAtivadas}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          congeladosQtdeLojasAtivadas: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoNegociado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Negociado"
                                value={pontoNatural.congeladosQtdeEspacoNegociado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          congeladosQtdeEspacoNegociado: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoExecutado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Executado"
                                value={pontoNatural.congeladosQtdeEspacoExecutado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          congeladosQtdeEspacoExecutado: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <SelectMult
                                name="marcaPositivadas"
                                label="Qual marca foi positivada?"
                                options={marcaList.map((item) => {
                                  return { value: item.produtoMarcaId, label: item.nome };
                                })}
                                placeholder="Seleção de até 5"
                                value={pontoNatural.congeladosMarcasPositivadas}
                                maxItem={5}
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          congeladosMarcasPositivadas:
                                            value.length <= 5
                                              ? value
                                              : item.congeladosMarcasPositivadas
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoExtra });
                                }}
                              />
                              <UploadImage
                                arquivos={pontoNatural.congeladosEvidenciaArquivo}
                                fullWidth
                                evidenciaId={formData.evidenciaId}
                                onChangeFile={(file) => {
                                  window.showLoader();
                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      return {
                                        ...item,
                                        congeladosEvidenciaArquivo: {
                                          ...item.congeladosEvidenciaArquivo,
                                          urlArquivo: file
                                        }
                                      };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({ ...formData, pontoNatural: pontoNaturalEdit });
                                  window.hideLoader();
                                }}
                                removeFile={(id) => {
                                  window.showLoader();
                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      return { ...item, congeladosEvidenciaArquivo: { id: id } };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({
                                    ...formData,
                                    pontoNatural: pontoNaturalEdit
                                  });
                                  window.hideLoader();
                                }}
                              />
                            </div>
                            <div className="col-4 mb-5">
                              <h5>EMBUTIDOS</h5>
                              {pontoNatural.embutidosInvestimento !== -1 && (
                                <InputCurrency
                                  label="Investimento Total"
                                  placeholder="2000"
                                  value={pontoNatural.embutidosInvestimento}
                                  onChange={(value) => {
                                    const newPontoNatural = formData.pontoNatural.map((item) =>
                                      item.id === pontoNatural.id
                                        ? { ...item, embutidosInvestimento: value }
                                        : item
                                    );
                                    setFormData({ ...formData, pontoNatural: newPontoNatural });
                                  }}
                                />
                              )}
                              <label className="form-label">Período Ativado</label>
                              <MyDatePicker
                                placeholderText="Data Inicial"
                                selected={pontoNatural.embutidosInicio}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, embutidosInicio: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <MyDatePicker
                                placeholderText="Data Final"
                                minDate={pontoNatural.embutidosInicio}
                                disabled={!pontoNatural.embutidosInicio}
                                selected={pontoNatural.embutidosFim}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, embutidosFim: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeLojasAtivadas"
                                type="number"
                                min="0"
                                label="Qtd. Lojas Ativadas"
                                value={pontoNatural.embutidosQtdeLojasAtivadas}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          embutidosQtdeLojasAtivadas: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoNegociado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Negociado"
                                value={pontoNatural.embutidosQtdeEspacoNegociado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          embutidosQtdeEspacoNegociado: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoExecutado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Executado"
                                value={pontoNatural.embutidosQtdeEspacoExecutado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          embutidosQtdeEspacoExecutado: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <SelectMult
                                name="marcasPositivadas"
                                label="Qual marca foi positivada?"
                                options={marcaList.map((item) => {
                                  return { value: item.produtoMarcaId, label: item.nome };
                                })}
                                placeholder="Seleção de até 5"
                                value={pontoNatural.embutidosMarcasPositivadas}
                                maxItem={5}
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          embutidosMarcasPositivadas:
                                            value.length <= 5
                                              ? value
                                              : item.embutidosMarcasPositivadas
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoExtra });
                                }}
                              />

                              <UploadImage
                                arquivos={pontoNatural.embutidosEvidenciaArquivo}
                                fullWidth
                                evidenciaId={formData.evidenciaId}
                                onChangeFile={(file) => {
                                  window.showLoader();

                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      return {
                                        ...item,
                                        embutidosEvidenciaArquivo: {
                                          ...item.embutidosEvidenciaArquivo,
                                          urlArquivo: file
                                        }
                                      };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({ ...formData, pontoNatural: pontoNaturalEdit });

                                  window.hideLoader();
                                }}
                                removeFile={(id) => {
                                  window.showLoader();
                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      // const arquivosEdit = item.embutidosEvidenciaArquivo.map(
                                      //   (arquivo) => {
                                      //     if (arquivo.id === id) {
                                      //       return { id: arquivo.id };
                                      //     } else {
                                      //       return arquivo;
                                      //     }
                                      //   }
                                      // );
                                      return { ...item, embutidosEvidenciaArquivo: { id: id } };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({
                                    ...formData,
                                    pontoNatural: pontoNaturalEdit
                                  });
                                  window.hideLoader();
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <h5>IN NATURA</h5>
                              {pontoNatural.inNaturaInvestimento !== -1 && (
                                <InputCurrency
                                  label="Investimento Total"
                                  placeholder="2000"
                                  value={pontoNatural.inNaturaInvestimento}
                                  onChange={(value) => {
                                    const newPontoNatural = formData.pontoNatural.map((item) =>
                                      item.id === pontoNatural.id
                                        ? { ...item, inNaturaInvestimento: value }
                                        : item
                                    );
                                    setFormData({ ...formData, pontoNatural: newPontoNatural });
                                  }}
                                />
                              )}
                              <label className="form-label">Período Ativado</label>
                              <MyDatePicker
                                placeholderText="Data Inicial"
                                selected={pontoNatural.inNaturaInicio}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, inNaturaInicio: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <MyDatePicker
                                placeholderText="Data Final"
                                minDate={pontoNatural.inNaturaInicio}
                                disabled={!pontoNatural.inNaturaInicio}
                                selected={pontoNatural.inNaturaFim}
                                onChange={(value) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? { ...item, inNaturaFim: value }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeLojasAtivadas"
                                type="number"
                                min="0"
                                label="Qtd. Lojas Ativadas"
                                value={pontoNatural.inNaturaQtdeLojasAtivadas}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          inNaturaQtdeLojasAtivadas: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoNegociado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Negociado"
                                value={pontoNatural.inNaturaQtdeEspacoNegociado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          inNaturaQtdeEspacoNegociado: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <Input
                                name="qtdeEspacoExecutado"
                                type="number"
                                min="0"
                                label="Qtd. Espaço Executado"
                                value={pontoNatural.inNaturaQtdeEspacoExecutado}
                                onChange={(e) => {
                                  const newPontoNatural = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          inNaturaQtdeEspacoExecutado: parseInt(e.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoNatural });
                                }}
                              />
                              <SelectMult
                                name="marcaPositivadas"
                                label="Qual marca foi positivada?"
                                options={marcaList.map((item) => {
                                  return { value: item.produtoMarcaId, label: item.nome };
                                })}
                                placeholder="Seleção de até 5"
                                value={pontoNatural.inNaturaMarcasPositivadas}
                                maxItem={5}
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoNatural.map((item) =>
                                    item.id === pontoNatural.id
                                      ? {
                                          ...item,
                                          inNaturaMarcasPositivadas:
                                            value.length <= 5
                                              ? value
                                              : item.inNaturaMarcasPositivadas
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoNatural: newPontoExtra });
                                }}
                              />
                              <UploadImage
                                arquivos={pontoNatural.inNaturaEvidenciaArquivo}
                                fullWidth
                                evidenciaId={formData.evidenciaId}
                                onChangeFile={(file) => {
                                  window.showLoader();
                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      return {
                                        ...item,
                                        inNaturaEvidenciaArquivo: {
                                          ...item.inNaturaEvidenciaArquivo,
                                          urlArquivo: file
                                        }
                                      };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({ ...formData, pontoNatural: pontoNaturalEdit });

                                  window.hideLoader();
                                }}
                                removeFile={(id) => {
                                  window.showLoader();
                                  const pontoNaturalEdit = formData.pontoNatural.map((item) => {
                                    if (item.id === pontoNatural.id) {
                                      // const arquivosEdit = item.inNaturaEvidenciaArquivo.map(
                                      //   (arquivo) => {
                                      //     if (arquivo.id === id) {
                                      //       return { id: arquivo.id };
                                      //     } else {
                                      //       return arquivo;
                                      //     }
                                      //   }
                                      // );
                                      return { ...item, inNaturaEvidenciaArquivo: { id: id } };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({
                                    ...formData,
                                    pontoNatural: pontoNaturalEdit
                                  });
                                  window.hideLoader();
                                }}
                              />
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                })
              : null}
          </div>
          <div className="pontoExtra" ref={listRefPontoExtra}>
            {formData.pontoExtra && formData.pontoExtra.length
              ? formData.pontoExtra.map((pontoExtra) => {
                  return (
                    <>
                      <Accordion
                        defaultActiveKey={pontoExtra.id}
                        alwaysOpen
                        key={pontoExtra.id}
                        className="ponto-extra mb-4"
                        id={`ponto-extra-${pontoExtra.id}`}>
                        <Accordion.Item eventKey={pontoExtra.id}>
                          <Accordion.Header>
                            <h2 className="text-danger font-weight-bold w-100">
                              PONTO EXTRA {pontoExtra.id}
                              {/* <span className="float-end">
                              <button
                                type="button"
                                className={`btn btn-danger me-5`}
                                style={{
                                  borderRadius: '20px'
                                }}>
                                <FontAwesomeIcon icon={faTrashCan} />
                              </button>
                            </span> */}
                            </h2>
                          </Accordion.Header>
                          <Accordion.Body className="row">
                            <div>
                              <span className="float-end">
                                <button
                                  type="button"
                                  className={`btn btn-danger`}
                                  style={{
                                    borderRadius: '20px'
                                  }}
                                  onClick={() => {
                                    handleShow();
                                    setItemDelete({
                                      item: pontoExtra,
                                      tipo: 'ponto-extra'
                                    });
                                  }}>
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </button>
                              </span>
                            </div>

                            <label className="form-label">Período Ativado</label>
                            <div className="col-6">
                              <MyDatePicker
                                placeholderText="Data Inicial"
                                campoObrigatorio
                                selected={
                                  formData.pontoExtra.find((item) => item.id === pontoExtra.id)
                                    .inicio
                                }
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoExtra.map((item) =>
                                    item.id === pontoExtra.id ? { ...item, inicio: value } : item
                                  );
                                  setFormData({ ...formData, pontoExtra: newPontoExtra });
                                }}
                              />
                            </div>
                            <div className="col-6">
                              <MyDatePicker
                                placeholderText="Data Final"
                                minDate={
                                  formData.pontoExtra.find((item) => item.id === pontoExtra.id)
                                    .inicio
                                }
                                campoObrigatorio
                                disabled={!pontoExtra.inicio}
                                selected={
                                  formData.pontoExtra.find((item) => item.id === pontoExtra.id).fim
                                }
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoExtra.map((item) =>
                                    item.id === pontoExtra.id ? { ...item, fim: value } : item
                                  );
                                  setFormData({ ...formData, pontoExtra: newPontoExtra });
                                }}
                              />
                            </div>
                            {/* Investimento na Ação ------------------------------------------------------------------------------------------- */}
                            {pontoExtra.investimento !== -1 && (
                              <div className="col-6">
                                <InputCurrency
                                  label="Investimento na Ação"
                                  campoObrigatorio
                                  value={
                                    formData.pontoExtra.find((item) => item.id === pontoExtra.id)
                                      .investimento
                                  }
                                  onChange={(value) => {
                                    const newPontoExtra = formData.pontoExtra.map((item) =>
                                      item.id === pontoExtra.id
                                        ? { ...item, investimento: value }
                                        : item
                                    );
                                    setFormData({ ...formData, pontoExtra: newPontoExtra });
                                  }}
                                />
                              </div>
                            )}
                            <div
                              className={`${pontoExtra.investimento !== -1 ? 'col-6' : 'col-12'}`}>
                              <Input
                                name="qtdeSpreadsNegociado"
                                type="number"
                                campoObrigatorio
                                min="0"
                                label="Qtd. Lojas Ativadas"
                                value={pontoExtra.qtdeLojasAtivadas}
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoExtra.map((item) =>
                                    item.id === pontoExtra.id
                                      ? {
                                          ...item,
                                          qtdeLojasAtivadas: parseInt(value.target.value)
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoExtra: newPontoExtra });
                                }}
                              />
                            </div>
                            {/* <div className="row">
                            <div className="col-3">
                              <RadioBox
                                label="Teve uso de MPDV?"
                                options={[
                                  { label: 'Sim', value: true },
                                  { label: 'Não', value: false }
                                ]}
                                selected={pontoExtra.mpdvRadio}
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoExtra.map((item) =>
                                    item.id === pontoExtra.id
                                      ? { ...item, mpdvRadio: value }
                                      : item
                                  );
                                  if (value === false) {
                                    setFormData({ ...formData, pontoExtra: newPontoExtra });
                                  } else {
                                    setFormData({ ...formData, pontoExtra: newPontoExtra });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-9">
                              {pontoExtra.mpdvRadio && (
                                <SelectMult
                                  name="mpdvs"
                                  label="Qual MPDV foi utilizado?"
                                  options={mpdvList.map((item) => {
                                    return { value: item.materialComunicacaoId, label: item.nome };
                                  })}
                                  placeholder="Seleção de até 5"
                                  maxItem={5}
                                  value={pontoExtra.mpdvs}
                                  onChange={(value) => {
                                    const newPontoExtra = formData.pontoExtra.map((item) =>
                                      item.id === pontoExtra.id
                                        ? {
                                            ...item,
                                            mpdvs: value.length <= 5 ? value : item.mpdvs
                                          }
                                        : item
                                    );
                                    setFormData({ ...formData, pontoExtra: newPontoExtra });
                                  }}
                                />
                              )}
                            </div>
                          </div> */}
                            {/* <div className="row"> */}
                            <div className="col-3">
                              <RadioBox
                                label="Teve marca positivada?"
                                options={[
                                  { label: 'Sim', value: true },
                                  { label: 'Não', value: false }
                                ]}
                                selected={pontoExtra.marcaPositivadaRadio}
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoExtra.map((item) =>
                                    item.id === pontoExtra.id
                                      ? {
                                          ...item,
                                          marcaPositivadaRadio: value,
                                          marcasPositivadas:
                                            value === false ? [] : item.marcasPositivadas
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoExtra: newPontoExtra });
                                }}
                              />
                            </div>
                            <div className="col-9">
                              {pontoExtra.marcaPositivadaRadio && (
                                <SelectMult
                                  name="marcaPositivadas"
                                  label="Qual marca foi positivada?"
                                  options={marcaList.map((item) => {
                                    return { value: item.produtoMarcaId, label: item.nome };
                                  })}
                                  placeholder="Seleção de até 5"
                                  value={pontoExtra.marcasPositivadas}
                                  maxItem={5}
                                  campoObrigatorio
                                  onChange={(value) => {
                                    const newPontoExtra = formData.pontoExtra.map((item) =>
                                      item.id === pontoExtra.id
                                        ? {
                                            ...item,
                                            marcasPositivadas:
                                              value.length <= 5 ? value : item.marcasPositivadas
                                          }
                                        : item
                                    );
                                    setFormData({ ...formData, pontoExtra: newPontoExtra });
                                  }}
                                />
                              )}
                            </div>
                            {/* </div> */}

                            <div className="col-3">
                              <RadioBox
                                label="Teve categ. positivada?"
                                options={[
                                  { label: 'Sim', value: true },
                                  { label: 'Não', value: false }
                                ]}
                                selected={pontoExtra.categoriasPositivadaRadio}
                                onChange={(value) => {
                                  const newPontoExtra = formData.pontoExtra.map((item) =>
                                    item.id === pontoExtra.id
                                      ? {
                                          ...item,
                                          categoriasPositivadaRadio: value,
                                          categoriasPositivadas:
                                            value === false ? [] : item.categoriasPositivadas
                                        }
                                      : item
                                  );
                                  setFormData({ ...formData, pontoExtra: newPontoExtra });
                                }}
                              />
                            </div>
                            <div className="col-9">
                              {pontoExtra.categoriasPositivadaRadio && (
                                <SelectMult
                                  name="categoriasPositivasList"
                                  label="Qual categoria foi positivada?"
                                  options={categoriasPositivasList.map((item) => {
                                    return {
                                      value: item.produtoCategoriaId,
                                      label: item.nome
                                    };
                                  })}
                                  campoObrigatorio
                                  placeholder="Seleção de até 5"
                                  value={pontoExtra.categoriasPositivadas}
                                  maxItem={5}
                                  onChange={(value) => {
                                    const newPontoExtra = formData.pontoExtra.map((item) =>
                                      item.id === pontoExtra.id
                                        ? {
                                            ...item,
                                            categoriasPositivadas:
                                              value.length <= 5 ? value : item.categoriasPositivadas
                                          }
                                        : item
                                    );
                                    setFormData({ ...formData, pontoExtra: newPontoExtra });
                                  }}
                                />
                              )}
                            </div>

                            <div className="col-12 qtd-espaco">
                              <h4>QUANTIDADE DE ESPAÇO</h4>
                              <div className="row">
                                <div className="col-4"></div>
                                <div className="col-4">
                                  <label>Espaço Negociado</label>
                                </div>
                                <div className="col-4">
                                  <label>Espaço Executado</label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4 d-flex align-items-center">
                                  <label className="form-label invisible" />
                                  Categoria Spreads
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeSpreadsNegociado"
                                    type="number"
                                    noLabel
                                    min="0"
                                    value={pontoExtra.qtdeSpreadsNegociado}
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeSpreadsNegociado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeSpreadsExecutado"
                                    type="number"
                                    noLabel
                                    min="0"
                                    value={pontoExtra.qtdeSpreadsExecutado}
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeSpreadsExecutado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4 d-flex align-items-center">
                                  Categoria Frios
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeFriosNegociado"
                                    type="number"
                                    min="0"
                                    value={pontoExtra.qtdeFriosNegociado}
                                    noLabel
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeFriosNegociado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeFriosExecutado"
                                    type="number"
                                    min="0"
                                    value={pontoExtra.qtdeFriosExecutado}
                                    noLabel
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeFriosExecutado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4 d-flex align-items-center">
                                  Categoria Congelados
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeCongeladosNegociado"
                                    type="number"
                                    min="0"
                                    value={pontoExtra.qtdeCongeladosNegociado}
                                    noLabel
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeCongeladosNegociado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeCongeladosExecutado"
                                    type="number"
                                    min="0"
                                    noLabel
                                    value={pontoExtra.qtdeCongeladosExecutado}
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeCongeladosExecutado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4 d-flex align-items-center">
                                  Categoria Embutidos
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeEmbutidosNegociado"
                                    type="number"
                                    min="0"
                                    value={pontoExtra.qtdeEmbutidosNegociado}
                                    noLabel
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeEmbutidosNegociado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeEmbutidosExecutado"
                                    type="number"
                                    noLabel
                                    min="0"
                                    value={pontoExtra.qtdeEmbutidosExecutado}
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeEmbutidosExecutado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4 d-flex align-items-center">
                                  {' '}
                                  Categoria In Natura
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeInNaturaNegociado"
                                    type="number"
                                    min="0"
                                    value={pontoExtra.qtdeInNaturaNegociado}
                                    noLabel
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeInNaturaNegociado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="qtdeInNaturaExecutado"
                                    noLabel
                                    type="number"
                                    min="0"
                                    value={pontoExtra.qtdeInNaturaExecutado}
                                    onChange={(value) => {
                                      const newPontoExtra = formData.pontoExtra.map((item) =>
                                        item.id === pontoExtra.id
                                          ? {
                                              ...item,
                                              qtdeInNaturaExecutado: parseInt(value.target.value)
                                            }
                                          : item
                                      );
                                      setFormData({ ...formData, pontoExtra: newPontoExtra });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {pontoExtra.arquivos && (
                              <UploadImage
                                arquivos={pontoExtra.arquivos}
                                evidenciaId={formData.evidenciaId}
                                onChangeFile={(file, id) => {
                                  window.showLoader();
                                  const pontoExtraEdit = formData.pontoExtra.map((item) => {
                                    if (item.id === pontoExtra.id) {
                                      const arquivosEdit = item.arquivos.map((arquivo) => {
                                        if (arquivo.id === id) {
                                          return { ...arquivo, urlArquivo: file };
                                        } else {
                                          return arquivo;
                                        }
                                      });
                                      return { ...item, arquivos: arquivosEdit };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({ ...formData, pontoExtra: pontoExtraEdit });

                                  window.hideLoader();
                                }}
                                removeFile={(id) => {
                                  window.showLoader();

                                  const pontoExtraEdit = formData.pontoExtra.map((item) => {
                                    if (item.id === pontoExtra.id) {
                                      const arquivosEdit = item.arquivos.map((arquivo) => {
                                        if (arquivo.id === id) {
                                          return { id: arquivo.id };
                                        } else {
                                          return arquivo;
                                        }
                                      });
                                      return { ...item, arquivos: arquivosEdit };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setFormData({ ...formData, pontoExtra: pontoExtraEdit });
                                  window.hideLoader();
                                }}
                              />
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      {/* <div className="row p-3 ponto-extra mb-4" key={index}>

                    </div> */}
                    </>
                  );
                })
              : null}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-lg m-2"
            onClick={() => {
              flushSync(() => {
                setFormData({
                  ...formData,
                  pontoNatural: [
                    ...formData.pontoNatural,
                    {
                      ...construtorPontoNatural,
                      id: parseInt(formData.pontoNatural.length + 1),
                      evidenciaId: formData.evidenciaId,
                      spreadsInvestimento: formData.investimentoJBPRadio === true ? -1 : 0,
                      friosInvestimento: formData.investimentoJBPRadio === true ? -1 : 0,
                      congeladosInvestimento: formData.investimentoJBPRadio === true ? -1 : 0,
                      embutidosInvestimento: formData.investimentoJBPRadio === true ? -1 : 0,
                      inNaturaInvestimento: formData.investimentoJBPRadio === true ? -1 : 0
                    }
                  ]
                });
              });
              // 👇 Scroll to the last element in the list
              listRefPontoNatural.current?.lastElementChild?.scrollIntoView();
            }}>
            + ADICIONAR PONTO NATURAL
          </Button>
          <Button
            className="btn-lg m-2"
            onClick={() => {
              flushSync(() => {
                setFormData({
                  ...formData,
                  pontoExtra: [
                    ...formData.pontoExtra,
                    {
                      ...construtorPontoExtra,
                      id: parseInt(formData.pontoExtra.length + 1),
                      evidenciaId: formData.evidenciaId,
                      investimento: formData.investimentoJBPRadio ? -1 : 0
                    }
                  ]
                });
              });
              listRefPontoExtra.current?.lastElementChild?.scrollIntoView();
            }}>
            + ADICIONAR PONTO EXTRA
          </Button>
        </div>
        {/* Botoes Voltar/Salvar ---------------------------------------------------------------------------------------------- */}
        <div className="d-flex justify-content-between pt-5">
          <BackButton />
          <SaveButton
            onClick={async () => {
              const validacao = await validacaoFormulario();
              if (validacao) {
                flushSync(() => {
                  setChangeCount(0);
                });
                nextTab();
              } else {
                window.scrollTo(0, 0);
              }
            }}
          />
        </div>
      </form>

      {/* Modal confirmação  */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>
            {itemDelete.tipo === 'ponto-extra'
              ? `PONTO EXTRA ${itemDelete.item.id}`
              : `PONTO NATURAL ${itemDelete.item.id}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            <p className="h4 text-center">
              Ao clicar em descartar, seu ponto{' '}
              {itemDelete.tipo === 'ponto-extra' ? 'extra' : 'natural'} será apagado e você não
              poderá continuá-lo ou acessar as informações. Deseja continuar?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="danger"
            className="ms-2 p-2 fw-semibold"
            width="320px"
            onClick={() => setShow(false)}>
            CANCELAR
          </Button>
          <Button
            className="ms-2 p-2 fw-semibold btn-danger"
            width="320px"
            onClick={() => removeItemModal(itemDelete.item, itemDelete.tipo)}>
            DESCARTAR
          </Button>
        </Modal.Footer>
      </Modal>
      <TimerRascunho
        changeCount={changeCount}
        setChangeCount={setChangeCount}
        formData={formData}
      />
    </div>
  );
};

export default Form;
