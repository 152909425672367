import React, { useEffect, useRef, useState } from 'react';
import BackButton from 'components/BackButton';
import MyDatePicker from 'components/DatePicker';

import SaveButton from 'components/SaveButton';
import TextArea from 'components/TextArea';
import UploadImage from 'components/UploadImage';
import SelectMult from 'components/SelectMult';
import SearchLoja from 'components/SearchLoja';
import TimerRascunho from 'components/TimerRascunho';
import { flushSync } from 'react-dom';
import InvestimentoJBP from 'components/InvestimentoJBP';

const Form = ({ formData, setFormData, nextTab, tipoArquivoList }) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }
  }, [formData]);

  const validacaoFormulario = async () => {
    let todosErros = [];

    // validação de campos obrigatorios
    !formData.lojaId && todosErros.push('Loja');
    !formData.tipoMidia.length && todosErros.push('Tipo de Mídia');
    !formData.inicio && todosErros.push('Inicio do Período da Ação');
    !formData.fim && todosErros.push('Fim do Período da Ação');
    !formData.investimento && todosErros.push('Investimento');
    !formData.resultados && todosErros.push('Aprendizados e Pontos de Melhoria');
    const arquivosWithFile = formData.arquivos.filter((item) => item.file || item.urlArquivo);
    !arquivosWithFile.length && todosErros.push('Arquivos');

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeFile = (file, id) => {
    window.showLoader();
    // if (e.target.files.length === 0) return;
    // const file = e.target.files[0];
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return { ...item, urlArquivo: file };
      } else {
        return item;
      }
    });

    window.hideLoader();

    setFormData({ ...formData, arquivos: arquivos });
  };

  const removeFile = (id) => {
    window.showLoader();
    const arquivos = formData.arquivos.map((item) => {
      if (item.id === id) {
        return { id: item.id };
      } else {
        return item;
      }
    });
    window.hideLoader();

    setFormData({ ...formData, arquivos: arquivos });
  };

  return (
    <div className="row">
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo <b>{value}</b> é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>
      {/* <SearchRede changeRede={setFormData} formData={formData} /> */}
      <SearchLoja changeLoja={setFormData} formData={formData} campoObrigatorio />
      <div className="col-12">
        <SelectMult
          name="categoriasPositivadas"
          label="Qual o tipo de mídia?"
          options={tipoArquivoList}
          placeholder="Selecione até 5"
          campoObrigatorio
          value={formData.tipoMidia}
          maxItem={5}
          onChange={(value) =>
            setFormData({
              ...formData,
              tipoMidia: value.length <= 5 ? value : formData.tipoMidia
            })
          }
        />
      </div>
      <h5>Vigência da Ação</h5>
      <div className="col-6">
        <MyDatePicker
          placeholderText="Data Inicial"
          selected={formData.inicio}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, inicio: value })}
        />
      </div>
      <div className="col-6">
        <MyDatePicker
          placeholderText="Data Final"
          selected={formData.fim}
          minDate={formData.inicio}
          disabled={!formData.inicio}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, fim: value })}
        />
      </div>
      {/* <div className="col-12">
        <InputCurrency
          value={formData.investimento}
          campoObrigatorio
          onChange={(value) => setFormData({ ...formData, investimento: value })}
          label="Investimento"
        />
      </div> */}
      <InvestimentoJBP formData={formData} setFormData={setFormData} />
      {/* Aprendizados e Pontos de Melhoria ------------------------------------------------------------------------------------------- */}
      <div className="col-12">
        <TextArea
          placeholder="Descreva os aprendizados e pontos de melhoria percebidos durante a ativação. Máximo 160 caracteres."
          label="Aprendizados e Pontos de Melhoria"
          row="5"
          col="160"
          max={160}
          value={formData.resultados}
          campoObrigatorio
          onChange={(e) => {
            if (e.target.value === undefined || e.target.value.length <= 160) {
              setFormData({
                ...formData,
                resultados: e.target.value
              });
            } else {
              setFormData({
                ...formData,
                resultados: e.target.value.substring(0, 160)
              });
            }
          }}
        />
      </div>
      {formData.arquivos && (
        <UploadImage
          arquivos={formData.arquivos}
          onChangeFile={onChangeFile}
          removeFile={removeFile}
          evidenciaId={formData.evidenciaId}
        />
      )}
      <div className="d-flex justify-content-between pt-5">
        <BackButton />
        {/* <SaveButton onClick={() => nextTab()} /> */}
        <SaveButton
          onClick={async () => {
            const validacao = await validacaoFormulario();
            if (validacao) {
              flushSync(() => {
                setChangeCount(0);
              });
              nextTab();
            } else {
              window.scrollTo(0, 0);
            }
          }}
        />
      </div>
      <TimerRascunho
        changeCount={changeCount}
        setChangeCount={setChangeCount}
        formData={formData}
      />
    </div>
  );
};

export default Form;
