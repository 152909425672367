import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FormBox from 'components/FormBox';
import PageTitle from 'components/PageTitle';
import Form from './form';

import Evidencia from 'services/Evidencia';
import Resume from './resume';
import jsonToFormData from 'helpers/jsonBuildFormData';
import EvidenciaAtivacaoEspaco from 'services/EvidenciaAtivacaoEspaco';

import construtorPontoNatural from './pontoNatural.json';
import construtorPontoExtra from './pontoExtra.json';
import { getDateFromStringJson } from 'helpers/Date';
import Rascunho from 'services/Rascunho';
// import { serialize } from 'object-to-formdata';
// import convertObjectToFormData from 'js-to-formdata/lib';

function Espaco() {
  const navigate = useNavigate();
  const { id, rascunho } = useParams();

  const [activeTab, setActiveTab] = useState({
    pagina: 'form',
    subTitle: 'Preencha os dados abaixo e inclua as mídias:'
  });
  const [formData, setFormData] = useState({
    evidenciaId: null,
    redeId: null,
    pontoNatural: [],
    pontoExtra: [],
    investimentoJBPRadio: false
  });
  const [errors, setErrors] = useState([]);

  const [mpdvList, setMpdvList] = useState([]);
  const [marcaList, setMarcaList] = useState([]);
  const [categoriasPositivasList, setCategoriasPositivasList] = useState([]);

  useEffect(() => {
    (async () => {
      window.showLoader();
      if (!formData.evidenciaId) {
        if (id > 0) {
          if (rascunho === 'rascunho') {
            let { value } = await Rascunho.GetRascunho(id);
            value = JSON.parse(value.rascunho);
            let pontoExtraEdit = [];
            let pontoNaturalEdit = [];

            if (value.pontoExtra.length !== 0) {
              pontoExtraEdit = value.pontoExtra.map((item, index) => {
                return {
                  ...item,
                  id: index + 1,
                  inicio: getDateFromStringJson(item.inicio),
                  fim: getDateFromStringJson(item.fim),
                  marcaPositivadaRadio: item.marcasPositivadas.length ? true : false,
                  categoriasPositivadaRadio: item.categoriasPositivadas.length ? true : false,
                  arquivos: item.arquivos.map((arquivo, index) => ({
                    ...arquivo,
                    ...item.arquivos[index]
                  }))
                };
              });
            }
            if (value.pontoNatural.length !== 0) {
              pontoNaturalEdit = value.pontoNatural.map((item, index) => {
                return {
                  ...item,
                  id: index + 1,
                  spreadsInicio: item.spreadsInicio
                    ? getDateFromStringJson(item.spreadsInicio)
                    : '',
                  spreadsFim: item.spreadsFim ? getDateFromStringJson(item.spreadsFim) : '',
                  friosInicio: item.friosInicio ? getDateFromStringJson(item.friosInicio) : '',
                  friosFim: item.friosFim ? getDateFromStringJson(item.friosFim) : '',
                  congeladosInicio: item.congeladosInicio
                    ? getDateFromStringJson(item.congeladosInicio)
                    : '',
                  congeladosFim: item.congeladosFim
                    ? getDateFromStringJson(item.congeladosFim)
                    : '',
                  embutidosInicio: item.embutidosInicio
                    ? getDateFromStringJson(item.embutidosInicio)
                    : '',
                  embutidosFim: item.embutidosFim ? getDateFromStringJson(item.embutidosFim) : '',
                  inNaturaInicio: item.inNaturaInicio
                    ? getDateFromStringJson(item.inNaturaInicio)
                    : '',
                  inNaturaFim: item.inNaturaFim ? getDateFromStringJson(item.inNaturaFim) : '',
                  spreadsEvidenciaArquivo: {
                    ...item.spreadsEvidenciaArquivo,
                    id: 0
                  },
                  friosEvidenciaArquivo: {
                    ...item.friosEvidenciaArquivo,
                    id: 0
                  },
                  congeladosEvidenciaArquivo: {
                    ...item.congeladosEvidenciaArquivo,
                    id: 0
                  },
                  embutidosEvidenciaArquivo: {
                    ...item.embutidosEvidenciaArquivo,
                    id: 0
                  },
                  inNaturaEvidenciaArquivo: {
                    ...item.inNaturaEvidenciaArquivo,
                    id: 0
                  }
                };
              });
            }

            const valueInvestimentoJBPRadio = () => {
              const extra = pontoExtraEdit.find((item) => item.investimento === -1);
              const natural = pontoNaturalEdit.find(
                (item) =>
                  item.spreadsInvestimento === -1 ||
                  item.friosInvestimento === -1 ||
                  item.congeladosInvestimento === -1 ||
                  item.embutidosInvestimento === -1 ||
                  item.inNaturaInvestimento
              );
              return Boolean(extra) || Boolean(natural);
            };

            setFormData({
              ...value,
              pontoExtra: pontoExtraEdit,
              pontoNatural: pontoNaturalEdit,
              investimentoJBPRadio: valueInvestimentoJBPRadio()
            });
          } else {
            const { value } = await EvidenciaAtivacaoEspaco.edit(id);
            let pontoExtraEdit = [];
            let pontoNaturalEdit = [];

            if (value.pontoExtra.length !== 0) {
              pontoExtraEdit = value.pontoExtra.map((item, index) => {
                return {
                  ...item,
                  id: index + 1,
                  inicio: getDateFromStringJson(item.inicio),
                  fim: getDateFromStringJson(item.fim),
                  marcaPositivadaRadio: item.marcasPositivadas.length ? true : false,
                  categoriasPositivadaRadio: item.categoriasPositivadas.length ? true : false,
                  arquivos: item.arquivos.map((arquivo, index) => ({
                    ...arquivo,
                    ...item.arquivos[index]
                  }))
                };
              });
            }
            if (value.pontoNatural.length !== 0) {
              pontoNaturalEdit = value.pontoNatural.map((item, index) => {
                return {
                  ...item,
                  id: index + 1,
                  spreadsInicio: item.spreadsInicio
                    ? getDateFromStringJson(item.spreadsInicio)
                    : '',
                  spreadsFim: item.spreadsFim ? getDateFromStringJson(item.spreadsFim) : '',
                  friosInicio: item.friosInicio ? getDateFromStringJson(item.friosInicio) : '',
                  friosFim: item.friosFim ? getDateFromStringJson(item.friosFim) : '',
                  congeladosInicio: item.congeladosInicio
                    ? getDateFromStringJson(item.congeladosInicio)
                    : '',
                  congeladosFim: item.congeladosFim
                    ? getDateFromStringJson(item.congeladosFim)
                    : '',
                  embutidosInicio: item.embutidosInicio
                    ? getDateFromStringJson(item.embutidosInicio)
                    : '',
                  embutidosFim: item.embutidosFim ? getDateFromStringJson(item.embutidosFim) : '',
                  inNaturaInicio: item.inNaturaInicio
                    ? getDateFromStringJson(item.inNaturaInicio)
                    : '',
                  inNaturaFim: item.inNaturaFim ? getDateFromStringJson(item.inNaturaFim) : '',
                  spreadsEvidenciaArquivo: {
                    ...item.spreadsEvidenciaArquivo,
                    id: 0
                  },
                  friosEvidenciaArquivo: {
                    ...item.friosEvidenciaArquivo,
                    id: 0
                  },
                  congeladosEvidenciaArquivo: {
                    ...item.congeladosEvidenciaArquivo,
                    id: 0
                  },
                  embutidosEvidenciaArquivo: {
                    ...item.embutidosEvidenciaArquivo,
                    id: 0
                  },
                  inNaturaEvidenciaArquivo: {
                    ...item.inNaturaEvidenciaArquivo,
                    id: 0
                  }
                };
              });
            }

            const valueInvestimentoJBPRadio = () => {
              const extra = pontoExtraEdit.find((item) => item.investimento === -1);
              const natural = pontoNaturalEdit.find(
                (item) =>
                  item.spreadsInvestimento === -1 ||
                  item.friosInvestimento === -1 ||
                  item.congeladosInvestimento === -1 ||
                  item.embutidosInvestimento === -1 ||
                  item.inNaturaInvestimento
              );
              return Boolean(extra) || Boolean(natural);
            };

            setFormData({
              ...value,
              pontoExtra: pontoExtraEdit,
              pontoNatural: pontoNaturalEdit,
              investimentoJBPRadio: valueInvestimentoJBPRadio()
            });
          }
        }
        //create
        else {
          const { value } = await EvidenciaAtivacaoEspaco.create();
          setFormData({ ...formData, evidenciaId: value.evidenciaId });
        }
      }
      const { value: material } = await Evidencia.GetListMaterial();
      setMpdvList(material);
      const { value: categoria } = await Evidencia.GetListCategoria();
      setCategoriasPositivasList(categoria);
      const { value: marca } = await Evidencia.GetListMarca();
      setMarcaList(marca);
      window.hideLoader();
    })();
  }, []);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const saveForm = async () => {
    window.showLoader();
    const newPontoNatural = formData.pontoNatural.length
      ? formData.pontoNatural.map((item) => {
          return {
            ...item,
            spreadsInvestimento: item.spreadsInicio ? item.spreadsInvestimento : 0,
            friosInvestimento: item.friosInicio ? item.friosInvestimento : 0,
            congeladosInvestimento: item.congeladosInicio ? item.congeladosInvestimento : 0,
            embutidosInvestimento: item.embutidosInicio ? item.embutidosInvestimento : 0,
            inNaturaInvestimento: item.inNaturaInicio ? item.inNaturaInvestimento : 0
          };
        })
      : [];
    const formDataConvert = jsonToFormData({ ...formData, pontoNatural: newPontoNatural });
    const data = await EvidenciaAtivacaoEspaco.save(formDataConvert);
    if (data.value) {
      window.hideLoader();
      navigate('/sucesso');
    } else {
      window.hideLoader();
      window.scrollTo(0, 0);
      setErrors(data.errors);
    }
  };

  const renderTab = () => {
    let contentNow = <h1>Página não encontrada</h1>;
    if (activeTab.pagina === 'form') {
      contentNow = (
        <Form
          formData={formData}
          setFormData={setFormData}
          construtorPontoNatural={construtorPontoNatural}
          construtorPontoExtra={construtorPontoExtra}
          nextTab={() => changeTab({ pagina: 'resume', subTitle: 'Confira todos os dados abaixo' })}
          mpdvList={mpdvList}
          marcaList={marcaList}
          categoriasPositivasList={categoriasPositivasList}
        />
      );
    }
    if (activeTab.pagina === 'resume') {
      contentNow = (
        <Resume
          backTab={() =>
            changeTab({ pagina: 'form', subTitle: 'Preencha os dados abaixo e inclua as mídias:' })
          }
          saveForm={() => saveForm()}
          formData={formData}
          mpdvList={mpdvList}
          marcaList={marcaList}
          categoriasPositivasList={categoriasPositivasList}
        />
      );
    }
    return contentNow;
  };

  return (
    <div className="aticacaoPontoExtra">
      <PageTitle subTitle="Preencha os dados abaixo e inclua as mídias:">
        ATIVAÇÕES - ESPAÇO
      </PageTitle>
      <FormBox>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            Erro ao realizar o envio, por favor confira os dados e tente novamente.
          </div>
        )}

        <div className="row">{renderTab()}</div>
      </FormBox>
    </div>
  );
}

export default Espaco;
