import React from 'react';

import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import { Table } from 'react-bootstrap';
import { numberToStringCurrency } from 'helpers/money';

const Resume = ({ backTab, saveForm, formData, mpdvList }) => {
  const mpdvs = formData.mpdvs.map(
    (mpdv) => mpdvList.find((x) => x.materialComunicacaoId === mpdv.materialComunicacaoId)?.nome
  );

  return (
    <>
      <Table striped hover>
        <tbody>
          <tr>
            <td className="col-4">
              <b>Rede</b>
            </td>
            <td className="col-8">{formData.rede?.nome}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Observações</b>
            </td>
            <td className="col-8">{formData.resultados}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Investimento</b>
            </td>
            <td className="col-8">
              {formData.investimento === -1
                ? 'Ativação contemplada no JBP'
                : numberToStringCurrency(formData.investimento)}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Qtd. Total de Lojas</b>
            </td>
            <td className="col-8">{formData.qtdLojasTotal}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Qtd. Lojas Ativadas</b>
            </td>
            <td className="col-8">{formData.qtdLojasAtivadas}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Tipo de Mídia</b>
            </td>
            <td className="col-8">{mpdvs.join(', ')}</td>
          </tr>
        </tbody>
      </Table>

      <div>
        <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
        {formData.mpdvs.length &&
          formData.mpdvs
            .filter((item) => item.arquivo.file || item.arquivo.urlArquivo?.length > 0)
            .map((item, index) =>
              item.arquivo.file ? (
                <img
                  key={index}
                  src={URL.createObjectURL(item.arquivo.file)}
                  style={{ maxHeight: '240px' }}
                  className="img-fluid"
                  alt="imagem"
                />
              ) : item.arquivo.urlArquivo?.length > 0 ? (
                <img
                  key={index}
                  src={item.arquivo.urlArquivo}
                  style={{ maxHeight: '240px' }}
                  className="img-fluid"
                  alt="imagem"
                />
              ) : null
            )}
      </div>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
