import getCampoObrigatorioClassName from 'helpers/campoObrigatorio';
import React from 'react';

const TextArea = ({
  name,
  type = 'text',
  placeholder = '',
  label,
  disabled = false,
  max = 0,
  rows = 2,
  onChange,
  value,
  className = '',
  campoObrigatorio = false
}) => {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <textarea
        className={`form-control ${className} ${
          campoObrigatorio && getCampoObrigatorioClassName(value)
        }`}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        id={name}
        onChange={onChange}
        value={value}
        rows={rows}
      />
      <div className="d-flex d-flex justify-content-between">
        <span id="textcount text-danger">
          {value !== undefined && value.length === max && 'Número máximo de caracteres atingidos'}
        </span>
        {/* </div>
      <div className="d-flex justify-content-end"> */}
        <span id="words_count">
          {value !== undefined ? value.length : '0'}/{max}
        </span>
      </div>
    </div>
  );
};

export default TextArea;
