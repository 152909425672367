import React, { useEffect, useState } from 'react';
import AppTitle from 'components/AppTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from 'components/Button';
import Rascunho from 'services/Rascunho';
import { dateFormat } from 'helpers/Date';

const Rascunhos = () => {
  const [rascunhosLista, setRascunhosLista] = useState([]);
  const [modal, setModal] = useState({
    status: false,
    data: []
  });

  const listEvidenciaTipo = [
    { id: 1, url: '/ativacoes/acoes-lmpm', nome: 'Ações LMPM' },
    { id: 2, url: '/ativacoes/encartes', nome: 'Encartes' },
    { id: 3, url: '/ativacoes/ponto-extra', nome: 'Ponto Extra' },
    { id: 7, url: '/eventos', nome: 'Eventos' },
    { id: 8, url: '/visibilidade', nome: 'Visibilidade' },
    { id: 9, url: '/ativacoes/digital', nome: 'Digital' },
    { id: 10, url: '/ativacoes/espaco', nome: 'Espaço' },
    { id: 11, url: '/ativacoes/scanntech', nome: 'Scanntech' }
  ];

  const desativarRascunho = async (id) => {
    await Rascunho.GetDesativarRascunho(id);
  };

  const getRascunhos = async () => {
    const { value } = await Rascunho.GetListRascunho();
    const newValue = value.map((item) => {
      const jsonValue = JSON.parse(item.rascunho);
      return { ...item, ...jsonValue };
    });
    setRascunhosLista(newValue);
  };

  useEffect(() => {
    (async () => {
      window.showLoader();
      await getRascunhos();
      window.hideLoader();
    })();
  }, []);

  return (
    <div className="container-rascunho">
      <AppTitle>MEUS RASCUNHOS</AppTitle>
      <div className="table-rascunho">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Nro</th>
                <th>Data</th>
                <th>Tipo</th>
                <th className="text-center">Continuar</th>
                <th className="text-center">Descartar</th>
              </tr>
            </thead>
            <tbody>
              {rascunhosLista.length
                ? rascunhosLista.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.evidenciaId}</td>
                        <td>{dateFormat(item.dataCriacao)}</td>
                        <td>
                          {
                            listEvidenciaTipo.find(
                              (evidencia) => item.evidencia.evidenciaTipoId === evidencia.id
                            ).nome
                          }
                        </td>
                        <td className="text-center">
                          <Link
                            to={`${
                              listEvidenciaTipo.find(
                                (evidencia) => item.evidencia.evidenciaTipoId === evidencia.id
                              ).url
                            }/rascunho/${item.evidenciaId}`}
                            className="text-danger">
                            <FontAwesomeIcon icon={faPen} />
                          </Link>
                        </td>
                        <td className="text-center">
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setModal({ status: true, data: item })}
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={modal.status} onHide={() => setModal({ status: false, data: [] })} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Tem certeza que deseja descartar esse rascunho?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h4>
            Nro: <span className="text-danger">{modal.data.evidenciaId}</span>
          </h4>
          Ao clicar em descartar, seu rascunho será apagado e você não poderá continuá-lo ou acessar
          as informações. Deseja continuar?
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center">
          <Button color="grey" onClick={() => setModal({ status: false, data: [] })}>
            CANCELAR
          </Button>
          <Button
            color="danger"
            onClick={async () => {
              window.showLoader();
              await desativarRascunho(modal.data.evidenciaId);
              await getRascunhos();
              setModal({ status: false, data: [] });
              window.hideLoader();
            }}>
            DESCARTAR
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Rascunhos;
