import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

const HomeButton = () => {
  return (
    <div className="homeButton">
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-3 py-1 d-flex align-items-center">
            <ul className="orange">
              <Link to="/ativacoes/acoes-lmpm" className="text-decoration-none">
                <li>LMPM</li>
              </Link>

              <Link to="/ativacoes/espaco" className="text-decoration-none">
                <li>Espaço</li>
              </Link>

              <Link to="/ativacoes/encartes" className="text-decoration-none">
                <li>Encartes</li>
              </Link>

              <Link to="/ativacoes/scanntech" className="text-decoration-none">
                <li>Scanntech</li>
              </Link>

              <Link to="/ativacoes/digital" className="text-decoration-none">
                <li>Digital</li>
              </Link>
            </ul>
          </div>
          <div className="col-6 col-md-3 text-center py-1">
            {/* <Link to="/ativacoes" className="text-decoration-none"> */}
            <div className="button-orange d-flex justify-content-center align-items-center">
              <h2>
                Ativa-
                <br />
                ções
              </h2>
            </div>
            {/* </Link> */}
          </div>
          <div className="col-6 col-md-3 text-center py-1">
            {/* <Link to="/inauguracoes" className="text-decoration-none"> */}
            <div className="button-purple d-flex justify-content-center align-items-center">
              <h2>
                Anivers.
                <br />
                de Loja
              </h2>
            </div>
            {/* </Link> */}
          </div>
          <div className="col-6 col-md-3 py-1 d-flex align-items-center">
            <ul className={`purple`}>
              <li>EM BREVE!</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-3 py-1 d-flex align-items-center">
            <ul className={`red`}>
              <Link to="/eventos" className="text-decoration-none">
                <li>Eventos com vínculo ou não com Redes</li>
              </Link>
            </ul>
          </div>
          <div className="col-6 col-md-3 text-center py-1">
            {/* <Link to="/eventos" className="text-decoration-none"> */}
            <div className="button-red d-flex justify-content-center align-items-center">
              <h2>Eventos</h2>
            </div>
            {/* </Link> */}
          </div>
          <div className="col-6 col-md-3 text-center py-1">
            {/* <Link to="/visibilidade" className="text-decoration-none"> */}
            <div className="button-blue d-flex justify-content-center align-items-center">
              <h2>
                Visibi-
                <br />
                lidade
              </h2>
            </div>
            {/* </Link> */}
          </div>
          <div className="col-6 col-md-3 py-1 d-flex align-items-center">
            <ul className={`blue`}>
              <Link to="/visibilidade" className="text-decoration-none">
                <li>Mídias Indoor</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeButton;
