import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import React from 'react';
import { Table } from 'react-bootstrap';

import { dateFormat } from 'helpers/Date';
import { numberToStringCurrency } from 'helpers/money';

const Resume = ({ backTab, saveForm, formData, categoriasPositivasList, marcaList }) => {
  return (
    <>
      <Table striped hover>
        <tbody>
          <tr>
            <td className="col-4">
              <b>Tipo de Ativação</b>
            </td>
            <td className="col-8">ESPAÇO</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Rede</b>
            </td>
            <td className="col-8">{formData.rede?.nome}</td>
          </tr>
          {formData.investimentoJBPRadio && (
            <tr>
              <td className="col-4">
                <b>Investimento Total</b>
              </td>
              <td className="col-8">Ativação contemplada no JBP</td>
            </tr>
          )}
        </tbody>

        {formData.pontoNatural.length !== 0 &&
          formData.pontoNatural.map((pontoNatural) => {
            return (
              <>
                <div className="pontoExtra-Resume mt-4">
                  <h5>PONTO NATURAL {pontoNatural.id}</h5>
                </div>
                <br />
                {pontoNatural.spreadsInicio && (
                  <div>
                    <h6>SPREADS</h6>
                  </div>
                )}
                <tbody>
                  {
                    // SPREADS
                  }
                  {pontoNatural.spreadsInvestimento !== -1 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.spreadsInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.spreadsInicio && pontoNatural.spreadsFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.spreadsInicio)} -{' '}
                        {dateFormat(pontoNatural.spreadsFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.spreadsQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.spreadsQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.spreadsQtdeEspacoNegociado &&
                    pontoNatural?.spreadsQtdeEspacoExecutado && (
                      <tr>
                        <td className="col-4">
                          <b>Qtd. Espaço</b>
                        </td>
                        <td className="col-8">
                          Negociado: {pontoNatural.spreadsQtdeEspacoNegociado} <br />
                          Executado: {pontoNatural.spreadsQtdeEspacoExecutado}
                        </td>
                      </tr>
                    )}

                  {pontoNatural.spreadsMarcasPositivadas.length !== 0 && (
                    <tr>
                      <td className="col-4">
                        <b>Marcas Positivadas</b>
                      </td>
                      <td className="col-8">
                        {pontoNatural.spreadsMarcasPositivadas.map((item) => {
                          const data = marcaList.find(
                            (marcaPositivadas) => marcaPositivadas.produtoMarcaId === item
                          );
                          return <span key={data.produtoMarcaId}>{data.nome} </span>;
                        })}
                      </td>
                    </tr>
                  )}
                </tbody>

                {pontoNatural.friosInicio && (
                  <div>
                    <br />
                    <h6>FRIOS</h6>
                  </div>
                )}
                <tbody>
                  {
                    // Frios
                  }
                  {pontoNatural.friosInvestimento !== -1 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.friosInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.friosInicio && pontoNatural.friosFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.friosInicio)} - {dateFormat(pontoNatural.friosFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.friosQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.friosQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.friosQtdeEspacoNegociado &&
                    pontoNatural?.friosQtdeEspacoExecutado && (
                      <tr>
                        <td className="col-4">
                          <b>Qtd. Espaço</b>
                        </td>
                        <td className="col-8">
                          Negociado: {pontoNatural.friosQtdeEspacoNegociado} <br />
                          Executado: {pontoNatural.friosQtdeEspacoExecutado}
                        </td>
                      </tr>
                    )}

                  {pontoNatural.friosMarcasPositivadas.length !== 0 && (
                    <tr>
                      <td className="col-4">
                        <b>Marcas Positivadas</b>
                      </td>
                      <td className="col-8">
                        {pontoNatural.friosMarcasPositivadas.map((item) => {
                          const data = marcaList.find(
                            (marcaPositivadas) => marcaPositivadas.produtoMarcaId === item
                          );
                          return <span key={data.produtoMarcaId}>{data.nome} </span>;
                        })}
                      </td>
                    </tr>
                  )}
                </tbody>

                {pontoNatural.congeladosInicio && (
                  <div>
                    <br />
                    <h6>CONGELADOS</h6>
                  </div>
                )}
                <tbody>
                  {
                    // CONGELADOS
                  }
                  {pontoNatural.congeladosInvestimento !== -1 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.congeladosInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.congeladosInicio && pontoNatural.congeladosFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.congeladosInicio)} -{' '}
                        {dateFormat(pontoNatural.congeladosFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.congeladosQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.congeladosQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.congeladosQtdeEspacoNegociado &&
                    pontoNatural?.congeladosQtdeEspacoExecutado && (
                      <tr>
                        <td className="col-4">
                          <b>Qtd. Espaço</b>
                        </td>
                        <td className="col-8">
                          Negociado: {pontoNatural.congeladosQtdeEspacoNegociado} <br />
                          Executado: {pontoNatural.congeladosQtdeEspacoExecutado}
                        </td>
                      </tr>
                    )}

                  {pontoNatural.congeladosMarcasPositivadas.length !== 0 && (
                    <tr>
                      <td className="col-4">
                        <b>Marcas Positivadas</b>
                      </td>
                      <td className="col-8">
                        {pontoNatural.congeladosMarcasPositivadas.map((item) => {
                          const data = marcaList.find(
                            (marcaPositivadas) => marcaPositivadas.produtoMarcaId === item
                          );
                          return <span key={data.produtoMarcaId}>{data.nome} </span>;
                        })}
                      </td>
                    </tr>
                  )}
                </tbody>

                {pontoNatural.embutidosInicio && (
                  <div>
                    <br />
                    <h6>EMBUTIDOS</h6>
                  </div>
                )}
                <tbody>
                  {
                    // CONGELADOS
                  }
                  {pontoNatural.embutidosInvestimento !== -1 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.embutidosInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.embutidosInicio && pontoNatural.embutidosFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.embutidosInicio)} -{' '}
                        {dateFormat(pontoNatural.embutidosFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.embutidosQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.embutidosQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.embutidosQtdeEspacoNegociado &&
                    pontoNatural?.embutidosQtdeEspacoExecutado && (
                      <tr>
                        <td className="col-4">
                          <b>Qtd. Espaço</b>
                        </td>
                        <td className="col-8">
                          Negociado: {pontoNatural.embutidosQtdeEspacoNegociado} <br />
                          Executado: {pontoNatural.embutidosQtdeEspacoExecutado}
                        </td>
                      </tr>
                    )}

                  {pontoNatural.embutidosMarcasPositivadas.length !== 0 && (
                    <tr>
                      <td className="col-4">
                        <b>Marcas Positivadas</b>
                      </td>
                      <td className="col-8">
                        {pontoNatural.embutidosMarcasPositivadas.map((item) => {
                          const data = marcaList.find(
                            (marcaPositivadas) => marcaPositivadas.produtoMarcaId === item
                          );
                          return <span key={data.produtoMarcaId}>{data.nome} </span>;
                        })}
                      </td>
                    </tr>
                  )}
                </tbody>

                {pontoNatural.inNaturaInicio && (
                  <div>
                    <br />
                    <h6>IN NATURA</h6>
                  </div>
                )}
                <tbody>
                  {
                    // CONGELADOS
                  }
                  {pontoNatural.inNaturaInvestimento !== -1 && (
                    <>
                      <tr>
                        <td className="col-4">
                          <b>Investimento Total</b>
                        </td>
                        <td className="col-8">
                          {numberToStringCurrency(pontoNatural.inNaturaInvestimento)}
                        </td>
                      </tr>
                    </>
                  )}
                  {pontoNatural.inNaturaInicio && pontoNatural.inNaturaFim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoNatural.inNaturaInicio)} -{' '}
                        {dateFormat(pontoNatural.inNaturaFim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoNatural.inNaturaQtdeLojasAtivadas && (
                    <tr>
                      <td className="col-4">
                        <b>Qtd. Lojas Ativadas</b>
                      </td>
                      <td className="col-8">{pontoNatural.inNaturaQtdeLojasAtivadas}</td>
                    </tr>
                  )}
                  {pontoNatural?.inNaturaQtdeEspacoNegociado &&
                    pontoNatural?.inNaturaQtdeEspacoExecutado && (
                      <tr>
                        <td className="col-4">
                          <b>Qtd. Espaço</b>
                        </td>
                        <td className="col-8">
                          Negociado: {pontoNatural.inNaturaQtdeEspacoNegociado} <br />
                          Executado: {pontoNatural.inNaturaQtdeEspacoExecutado}
                        </td>
                      </tr>
                    )}

                  {pontoNatural.inNaturaMarcasPositivadas.length !== 0 && (
                    <tr>
                      <td className="col-4">
                        <b>Marcas Positivadas</b>
                      </td>
                      <td className="col-8">
                        {pontoNatural.inNaturaMarcasPositivadas.map((item) => {
                          const data = marcaList.find(
                            (marcaPositivadas) => marcaPositivadas.produtoMarcaId === item
                          );
                          return <span key={data.produtoMarcaId}>{data.nome} </span>;
                        })}
                      </td>
                    </tr>
                  )}
                </tbody>

                <div>
                  <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>

                  {pontoNatural.spreadsEvidenciaArquivo.file ? (
                    <>
                      <p className="text-center">SPREADS</p>
                      <img
                        src={URL.createObjectURL(pontoNatural.spreadsEvidenciaArquivo.file)}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : pontoNatural.spreadsEvidenciaArquivo.urlArquivo?.length > 0 ? (
                    <>
                      <p className="text-center">SPREADS</p>
                      <img
                        src={pontoNatural.spreadsEvidenciaArquivo.urlArquivo}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : null}

                  {pontoNatural.friosEvidenciaArquivo.file ? (
                    <>
                      <p className="text-center">FRIOS</p>
                      <img
                        src={URL.createObjectURL(pontoNatural.friosEvidenciaArquivo.file)}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : pontoNatural.friosEvidenciaArquivo.urlArquivo?.length > 0 ? (
                    <>
                      <p className="text-center">FRIOS</p>
                      <img
                        src={pontoNatural.friosEvidenciaArquivo.urlArquivo}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : null}

                  {pontoNatural.congeladosEvidenciaArquivo.file ? (
                    <>
                      <p className="text-center">CONGELADOS</p>
                      <img
                        src={URL.createObjectURL(pontoNatural.congeladosEvidenciaArquivo.file)}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : pontoNatural.congeladosEvidenciaArquivo.urlArquivo?.length > 0 ? (
                    <>
                      <p className="text-center">CONGELADOS</p>
                      <img
                        src={pontoNatural.congeladosEvidenciaArquivo.urlArquivo}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : null}

                  {pontoNatural.embutidosEvidenciaArquivo.file ? (
                    <>
                      <p className="text-center">EMBUTIDOS</p>
                      <img
                        src={URL.createObjectURL(pontoNatural.embutidosEvidenciaArquivo.file)}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : pontoNatural.embutidosEvidenciaArquivo.urlArquivo?.length > 0 ? (
                    <>
                      <p className="text-center">EMBUTIDOS</p>
                      <img
                        src={pontoNatural.embutidosEvidenciaArquivo.urlArquivo}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : null}

                  {pontoNatural.inNaturaEvidenciaArquivofile ? (
                    <>
                      <p className="text-center">IN NATURA</p>
                      <img
                        src={URL.createObjectURL(pontoNatural.inNaturaEvidenciaArquivo.file)}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : pontoNatural.inNaturaEvidenciaArquivo.urlArquivo?.length > 0 ? (
                    <>
                      <p className="text-center">IN NATURA</p>
                      <img
                        src={pontoNatural.inNaturaEvidenciaArquivo.urlArquivo}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    </>
                  ) : null}
                </div>
              </>
            );
          })}

        {formData.pontoExtra.length !== 0 &&
          formData.pontoExtra.map((pontoExtra) => {
            return (
              <>
                <div className="pontoExtra-Resume mt-4">
                  <h5>PONTO EXTRA {pontoExtra.id}</h5>
                </div>
                <tbody>
                  {pontoExtra.inicio && pontoExtra.fim && (
                    <tr>
                      <td className="col-4">
                        <b>Período da Ação</b>
                      </td>
                      <td className="col-8">
                        {dateFormat(pontoExtra.inicio)} - {dateFormat(pontoExtra.fim)}{' '}
                      </td>
                    </tr>
                  )}
                  {pontoExtra.investimento !== -1 && (
                    <tr>
                      <td className="col-4">
                        <b>Investimento na Ação</b>
                      </td>
                      <td className="col-8">
                        {pontoExtra.investimento === -1
                          ? 'Ativação contemplada no JBP'
                          : numberToStringCurrency(pontoExtra.investimento)}
                      </td>
                    </tr>
                  )}
                  {pontoExtra.marcasPositivadas.length !== 0 && (
                    <tr>
                      <td className="col-4">
                        <b>Marcas Positivadas</b>
                      </td>
                      <td className="col-8">
                        {pontoExtra.marcasPositivadas.map((item) => {
                          const data = marcaList.find(
                            (marcaPositivadas) => marcaPositivadas.produtoMarcaId === item
                          );
                          return <span key={data.produtoMarcaId}>{data.nome} </span>;
                        })}
                      </td>
                    </tr>
                  )}
                  {pontoExtra.categoriasPositivadas.length !== 0 && (
                    <tr>
                      <td className="col-4">
                        <b>Categorias Positivadas</b>
                      </td>
                      <td className="col-8">
                        {pontoExtra.categoriasPositivadas.map((item) => {
                          const data = categoriasPositivasList.find(
                            (categoriasPositivadas) =>
                              categoriasPositivadas.produtoCategoriaId === item
                          );
                          return <span key={data.produtoCategoriaId}>{data.nome} </span>;
                        })}
                      </td>
                    </tr>
                  )}
                  {pontoExtra.qtdeSpreadsNegociado && pontoExtra.qtdeSpreadsExecutado && (
                    <tr>
                      <td className="col-4">
                        <b>Categoria Spreads</b>
                      </td>
                      <td className="col-8">
                        Negociado: {pontoExtra.qtdeSpreadsNegociado} <br />
                        Executado: {pontoExtra.qtdeSpreadsExecutado}
                      </td>
                    </tr>
                  )}

                  {pontoExtra.qtdeFriosNegociado && pontoExtra.qtdeFriosExecutado && (
                    <tr>
                      <td className="col-4">
                        <b>Categoria Frios</b>
                      </td>
                      <td className="col-8">
                        Negociado: {pontoExtra.qtdeFriosNegociado} <br />
                        Executado: {pontoExtra.qtdeFriosExecutado}
                      </td>
                    </tr>
                  )}

                  {pontoExtra.qtdeCongeladosNegociado && pontoExtra.qtdeCongeladosExecutado && (
                    <tr>
                      <td className="col-4">
                        <b>Categoria Congelados</b>
                      </td>
                      <td className="col-8">
                        Negociado: {pontoExtra.qtdeCongeladosNegociado} <br />
                        Executado: {pontoExtra.qtdeCongeladosExecutado}
                      </td>
                    </tr>
                  )}

                  {pontoExtra.qtdeEmbutidosNegociado && pontoExtra.qtdeEmbutidosExecutado && (
                    <tr>
                      <td className="col-4">
                        <b>Categoria Embutidos</b>
                      </td>
                      <td className="col-8">
                        Negociado: {pontoExtra.qtdeEmbutidosNegociado} <br />
                        Executado: {pontoExtra.qtdeEmbutidosExecutado}
                      </td>
                    </tr>
                  )}

                  {pontoExtra.qtdeInNaturaNegociado && pontoExtra.qtdeInNaturaExecutado && (
                    <tr>
                      <td className="col-4">
                        <b>Categoria In Natura</b>
                      </td>
                      <td className="col-8">
                        Negociado: {pontoExtra.qtdeInNaturaNegociado} <br />
                        Executado: {pontoExtra.qtdeInNaturaExecutado}
                      </td>
                    </tr>
                  )}
                </tbody>

                <div>
                  <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
                  {pontoExtra.arquivos
                    .filter((item) => item.file || item.urlArquivo?.length > 0)
                    .map((item, index) =>
                      item.file ? (
                        <img
                          key={index}
                          src={URL.createObjectURL(item.file)}
                          style={{ maxHeight: '240px' }}
                          className="img-fluid"
                          alt="imagem"
                        />
                      ) : item.urlArquivo?.length > 0 ? (
                        <img
                          key={index}
                          src={item.urlArquivo}
                          style={{ maxHeight: '240px' }}
                          className="img-fluid"
                          alt="imagem"
                        />
                      ) : null
                    )}
                </div>
              </>
            );
          })}
      </Table>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
