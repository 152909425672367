import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FormBox from 'components/FormBox';
import PageTitle from 'components/PageTitle';
import Form from './form';
import EvidenciaAtivacaoDigital from 'services/EvidenciaAtivacaoDigital';
import Resume from './resume';
import jsonToFormData from 'helpers/jsonBuildFormData';
import { getDateFromStringJson } from 'helpers/Date';
import Evidencia from 'services/Evidencia';
import Rascunho from 'services/Rascunho';

function Digital() {
  const navigate = useNavigate();
  const { id, rascunho } = useParams();

  const [activeTab, setActiveTab] = useState({
    pagina: 'form',
    subTitle: 'Preencha os dados abaixo e inclua as mídias:'
  });
  const initialFormData = {
    evidenciaId: null,
    lojaId: null,
    tipoMidia: [],
    inicio: '',
    fim: '',
    investimento: '',
    resultados: '',
    arquivos: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
    investimentoJBPRadio: false
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState([]);

  const [tipoArquivoList, setTipoArquivoList] = useState([]);

  useEffect(() => {
    (async () => {
      window.showLoader();
      if (!formData.evidenciaId) {
        if (id > 0) {
          if (rascunho === 'rascunho') {
            let { value } = await Rascunho.GetRascunho(id);
            value = JSON.parse(value.rascunho);
            setFormData({
              ...formData,
              ...value,
              inicio: getDateFromStringJson(value.inicio),
              fim: getDateFromStringJson(value.fim),
              investimentoJBPRadio: Boolean(value.investimento === -1),
              arquivos: formData.arquivos.map((arquivo, index) => ({
                ...arquivo,
                ...value.arquivos[index]
              }))
            });
          } else {
            const { value } = await EvidenciaAtivacaoDigital.edit(id);
            setFormData({
              ...formData,
              ...value,
              inicio: getDateFromStringJson(value.inicio),
              fim: getDateFromStringJson(value.fim),
              investimentoJBPRadio: Boolean(value.investimento === -1),
              arquivos: formData.arquivos.map((arquivo, index) => ({
                ...arquivo,
                ...value.arquivos[index]
              }))
            });
          }
        }
        //create
        else {
          const { value } = await EvidenciaAtivacaoDigital.create();
          setFormData({ ...formData, evidenciaId: value.evidenciaId });
        }
      }
      let { value: tipoArquivoValue } = await Evidencia.GetListTipoArquivo();
      tipoArquivoValue = tipoArquivoValue.map((item) => {
        return {
          label: item.nome,
          value: item.onOffMidiaTipoId
        };
      });
      setTipoArquivoList(tipoArquivoValue);
      window.hideLoader();
    })();
  }, []);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const saveForm = async () => {
    window.showLoader();
    const arquivos = formData.arquivos.filter((item) => item.file || item.urlArquivo?.length > 0);
    const jsonData = {
      ...formData,
      arquivos: arquivos
    };

    const formRequest = jsonToFormData(jsonData);
    const data = await EvidenciaAtivacaoDigital.save(formRequest);
    if (data.value) {
      window.hideLoader();
      navigate('/sucesso');
    } else {
      window.hideLoader();
      window.scrollTo(0, 0);
      setErrors(data.errors);
    }
  };

  const renderTab = () => {
    let contentNow = <h1>Página não encontrada</h1>;
    if (activeTab.pagina === 'form') {
      contentNow = (
        <Form
          formData={formData}
          setFormData={setFormData}
          nextTab={() => changeTab({ pagina: 'resume', subTitle: 'Confira todos os dados abaixo' })}
          tipoArquivoList={tipoArquivoList}
          initialFormData={initialFormData}
        />
      );
    }
    if (activeTab.pagina === 'resume') {
      contentNow = (
        <Resume
          backTab={() =>
            changeTab({ pagina: 'form', subTitle: 'Preencha os dados abaixo e inclua as mídias:' })
          }
          saveForm={() => saveForm()}
          formData={formData}
          tipoArquivoList={tipoArquivoList}
        />
      );
    }
    return contentNow;
  };
  return (
    <div className="aticacaoDigital">
      <PageTitle subTitle="Preencha os dados abaixo e inclua as mídias:">
        ATIVAÇÕES - DIGITAL
      </PageTitle>
      <FormBox>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            Erro ao realizar o envio, por favor confira os dados e tente novamente.
          </div>
        )}

        <div className="row">{renderTab()}</div>
      </FormBox>
    </div>
  );
}

export default Digital;
