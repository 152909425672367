import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import Evidencia from 'services/Evidencia';

import './style.scss';
import { dateFormat } from 'helpers/Date';

const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];

function HistoricoEvidencias() {
  const [historico, setHistorico] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    listEvidencias();
  }, []);

  const listEvidencias = async () => {
    window.showLoader();
    const data = await Evidencia.GetHistorico();
    setHistorico(data.value);
    window.hideLoader();
  };

  const handleEdit = (evidencia) => {
    const tagsRedirect = {
      ativacao_lmpm: '/ativacoes/acoes-lmpm',
      ativacao_encartes: '/ativacoes/encartes',
      ativacao_espaco: '/ativacoes/espaco',
      ativacao_scanntech: '/ativacoes/scanntech',
      ativacao_degustacao: '/ativacoes/degustacao',
      ativacao_digital: '/ativacoes/digital',
      inauguracao_degustacao: '/inauguracoes/degustacao',
      inauguracao_midia_indoor: '/inauguracoes/midia-indoor',
      evento_interno_externo_parceiros: '/eventos',
      visibilidade_midia_indoor: '/visibilidade'
    };

    let url = `${tagsRedirect[evidencia.tag]}/${evidencia.evidenciaId}`;
    navigate(url);
  };

  return (
    <div className="historicoEvidencias">
      <div className="text-center">
        <Link to="/evidencias-regional">
          <Button type="button" className="btn btn-primary">
            EVIDÊNCIAS ENVIADAS DA REGIONAL
          </Button>
        </Link>
      </div>

      <hr />
      <PageTitle>Minhas Evidências</PageTitle>

      {historico.fechamentoMes > 0 ? (
        <div className="pb-4">
          <h5 className="text-danger text-center">
            EVIDÊNCIAS DO MÊS DE {meses[historico.fechamentoMes - 1].toUpperCase()}
          </h5>

          <h5 className="text-danger text-center">
            Atenção: as evidências só poderão ser editadas até o dia {dateFormat(historico.fim)}
          </h5>
        </div>
      ) : null}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>DATA</th>
            <th>TIPO</th>
            <th>REDE</th>
            <th>MANCHETE</th>
            <th className="text-center">EDITAR</th>
          </tr>
        </thead>
        <tbody>
          {historico?.evidencias?.map((evidencia) => (
            <tr key={evidencia.evidenciaId}>
              <td>{dateFormat(evidencia.data)}</td>
              <td>{evidencia.tipo}</td>
              <td>{evidencia.rede}</td>
              <td>{evidencia.manchete}</td>
              <td className="text-center">
                <button
                  className="btn btn-link text-danger p-0"
                  onClick={() => handleEdit(evidencia)}>
                  <FontAwesomeIcon icon={faPen} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default HistoricoEvidencias;
