/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import BackButton from 'components/BackButton';
import MyDatePicker from 'components/DatePicker';

import SaveButton from 'components/SaveButton';
import TextArea from 'components/TextArea';
import UploadImage from 'components/UploadImage';
import SelectMult from 'components/SelectMult';
import SearchLoja from 'components/SearchLoja';

import SemFoto from 'assets/imgs/SEMFOTO.png';
import SearchRede from 'components/SearchRede';
import { flushSync } from 'react-dom';
import TimerRascunho from 'components/TimerRascunho';

const Form = ({ formData, setFormData, nextTab, listAtivacaoScanntech }) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }
  }, [formData]);

  const validacaoFormulario = async () => {
    let todosErros = [];

    // validação de campos obrigatorios
    !formData.redeId && todosErros.push('Rede');
    if (!formData.ativacaoScanntech.length) {
      todosErros.push('Ativação');
    } else {
      formData.ativacaoScanntech.map((item) => {
        !item.observacoes && todosErros.push(item.nome + ' - Observações');
        !item?.arquivo?.urlArquivo && todosErros.push(item.nome + ' - Arquivo');
      });
    }

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const addAtivacao = (itemClick) => {
    const ativa = formData.ativacaoScanntech.find(
      (item) => item.ativacaoScanntechId === itemClick.ativacaoScanntechId
    );
    if (!ativa) {
      setFormData({
        ...formData,
        ativacaoScanntech: [
          ...formData.ativacaoScanntech,
          { ...itemClick, observacoes: '', arquivo: [{ id: 0 }] }
        ]
      });
    } else {
      const removeItem = formData.ativacaoScanntech.filter(
        (item) => item.ativacaoScanntechId !== itemClick.ativacaoScanntechId
      );
      setFormData({ ...formData, ativacaoScanntech: removeItem });
    }
  };

  const itemSelecionado = (id) => {
    if (formData.ativacaoScanntech.find((item) => item.ativacaoScanntechId === id)) {
      return 'selecionado';
    } else {
      return '';
    }
  };

  return (
    <div className="row">
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo <b>{value}</b> é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>
      <SearchRede changeRede={setFormData} formData={formData} />
      {/* <SearchLoja changeLoja={setFormData} formData={formData} campoObrigatorio /> */}
      <div>
        <label className="form-label">
          Ativação (Selecione abaixo quais ativações foram feitos nessa rede)
        </label>
        <div className="row container-ativacoes">
          <div className="col-sm-12 col-md-6 row">
            <h3 className="text-center">1° Quinzena</h3>
            {listAtivacaoScanntech && listAtivacaoScanntech.length !== 0 ? (
              listAtivacaoScanntech
                .filter((item) => item.quinzena === 1)
                .map((value, id) => (
                  <div
                    className={`col-md-6 col-sm-6 ativacoes-item mb-2 mt-2`}
                    key={value.ativacaoScanntechId}>
                    <div
                      className={`row pe-pointer item mx-1 ${itemSelecionado(
                        value.ativacaoScanntechId
                      )}`}
                      onClick={() =>
                        flushSync(async () => {
                          addAtivacao(value);
                        })
                      }>
                      <div className="col-sm-12 img-ativacao d-flex justify-content-center align-self-center align-items-center">
                        <img
                          src={value.urlImagem || SemFoto}
                          alt={value.nome}
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-sm-12">{value.nome}</div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="p-3 text-center">Nenhum ativação encontrada nesse período.</div>
            )}
          </div>
          <div className="col-sm-12 col-md-6 ativacao-quinzena">
            <div className="row ">
              <h3 className="text-center">2° Quinzena</h3>
              {listAtivacaoScanntech && listAtivacaoScanntech.length !== 0 ? (
                listAtivacaoScanntech
                  .filter((item) => item.quinzena === 2)
                  .map((value, id) => (
                    <div
                      className={`col-md-6 col-sm-6 ativacoes-item mb-2 mt-2`}
                      key={value.ativacaoScanntechId}>
                      <div
                        className={`row pe-pointer item mx-1 ${itemSelecionado(
                          value.ativacaoScanntechId
                        )}`}
                        onClick={() =>
                          flushSync(async () => {
                            addAtivacao(value);
                          })
                        }>
                        <div className="col-sm-12 img-ativacao d-flex justify-content-center align-self-center align-items-center">
                          <img
                            src={value.urlImagem || SemFoto}
                            alt={value.nome}
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-sm-12">{value.nome}</div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="p-3 text-center">Nenhum ativação encontrada nesse período.</div>
              )}
            </div>
          </div>
        </div>
        <div className="row container-ativacoes-selecionadas pt-4">
          {formData.ativacaoScanntech &&
            formData.ativacaoScanntech.length !== 0 &&
            formData.ativacaoScanntech.map((value, id) => (
              <div className="ativacoes-selecionadas-item mb-4" key={value.ativacaoScanntechId}>
                <h2 className="text-danger text-uppercase">{value.nome}</h2>
                <div className="row">
                  <div className="col-12 col-md-3 d-flex justify-content-center align-self-center">
                    <img src={value.urlImagem || SemFoto} alt={value.nome} className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-9">
                    <p className="mb-0">
                      <b>Produtos:</b> {value?.produto?.nome}
                    </p>
                    <p className="mb-0">
                      <b>Ativação:</b> {value?.ativacao}
                    </p>
                    <p className="mb-0">
                      <b>Período:</b> {value.mes}/{value.ano} - {value.quinzena}° quinzena
                    </p>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <TextArea
                    placeholder="Descreva quaisquer observações sobre a ação em no máximo 80 caracteres."
                    label="Pontos de atenção"
                    row="5"
                    col="80"
                    max={80}
                    value={value.observacoes || ''}
                    campoObrigatorio
                    onChange={(e) => {
                      const observacoesValue =
                        e.target.value === undefined || e.target.value.length <= 80
                          ? e.target.value
                          : e.target.value.substring(0, 80);
                      const newAtivacaoScanntech = formData.ativacaoScanntech.map((item) =>
                        value.ativacaoScanntechId === item.ativacaoScanntechId
                          ? {
                              ...item,
                              observacoes: observacoesValue
                            }
                          : item
                      );
                      setFormData({ ...formData, ativacaoScanntech: newAtivacaoScanntech });
                    }}
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <UploadImage
                    arquivos={value.arquivo}
                    fullWidth
                    evidenciaId={value.ativacaoScanntechId}
                    onChangeFile={(file) => {
                      window.showLoader();
                      const ativacaoScanntechEdit = formData.ativacaoScanntech.map((item) => {
                        if (item.ativacaoScanntechId === value.ativacaoScanntechId) {
                          return { ...item, arquivo: { urlArquivo: file } };
                        } else {
                          return item;
                        }
                      });
                      setFormData({ ...formData, ativacaoScanntech: ativacaoScanntechEdit });

                      window.hideLoader();
                    }}
                    removeFile={() => {
                      window.showLoader();

                      const ativacaoScanntechEdit = formData.ativacaoScanntech.map((item) => {
                        if (item.ativacaoScanntechId === value.ativacaoScanntechId) {
                          return { ...item, arquivo: { urlArquivo: '' } };
                        } else {
                          return item;
                        }
                      });
                      setFormData({ ...formData, ativacaoScanntech: ativacaoScanntechEdit });
                      window.hideLoader();
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="d-flex justify-content-between pt-5">
        <BackButton />
        <SaveButton
          onClick={async () => {
            const validacao = await validacaoFormulario();
            if (validacao) {
              nextTab();
            } else {
              window.scrollTo(0, 0);
            }
          }}
        />
      </div>
      <TimerRascunho
        changeCount={changeCount}
        setChangeCount={setChangeCount}
        formData={formData}
      />
    </div>
  );
};

export default Form;
