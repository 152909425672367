import React from 'react';
import { Table } from 'react-bootstrap';
import { dateFormat } from 'helpers/Date';
import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import { numberToStringCurrency } from 'helpers/money';

const Resume = ({ backTab, saveForm, formData }) => {
  return (
    <>
      <Table striped hover>
        <tbody>
          <tr>
            <td className="col-4">
              <b>Tipo de Ativação</b>
            </td>
            <td className="col-8">Encarte</td>
          </tr>
          {formData.solicitacao.solicitacaoId && (
            <>
              <tr>
                <td className="col-4">
                  <b>Número da Solicitação</b>
                </td>
                <td className="col-8">{formData.solicitacao?.solicitacaoId}</td>
              </tr>
              <tr>
                <td className="col-4">
                  <b>Loja</b>
                </td>
                <td className="col-8">{formData.solicitacao?.razaoSocial}</td>
              </tr>
              <tr>
                <td className="col-4">
                  <b>Vigência da Solicitação</b>
                </td>
                <td className="col-8">
                  {dateFormat(formData.solicitacao?.inicio)} -{' '}
                  {dateFormat(formData.solicitacao?.fim)}{' '}
                </td>
              </tr>
              <tr>
                <td className="col-4">
                  <b>Custo Multimaxi</b>
                </td>
                <td className="col-8">
                  {numberToStringCurrency(formData.solicitacao?.custoTotal)}
                </td>
              </tr>
            </>
          )}
          {formData.lojaId && (
            <>
              <tr>
                <td className="col-4">
                  <b>Loja</b>
                </td>
                <td className="col-8">{numberToStringCurrency(formData?.loja?.nome)}</td>
              </tr>
            </>
          )}
          {/* <tr>
            <td className="col-4">
              <b>Investimento da Ação</b>
            </td>
            <td className="col-8">{numberToStringCurrency(formData.investimento)}</td>
          </tr> */}
          <tr>
            <td className="col-4">
              <b>Investimento da Ação</b>
            </td>
            <td className="col-8">
              {formData.investimento === -1
                ? 'Ativação contemplada no JBP'
                : numberToStringCurrency(formData.investimento)}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Vigência do Impresso</b>
            </td>
            <td className="col-8">
              {dateFormat(formData.inicio)} - {dateFormat(formData.fim)}{' '}
            </td>
          </tr>
        </tbody>
      </Table>

      <div>
        <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
        {formData.arquivos
          .filter((item) => item.file || item.urlArquivo?.length || item.urlS3?.length > 0)
          .map((item, index) =>
            item.file ? (
              <img
                key={index}
                src={URL.createObjectURL(item.file)}
                style={{ maxHeight: '240px' }}
                className="img-fluid"
                alt="imagem"
              />
            ) : item.urlS3?.length > 0 ? (
              <img
                key={index}
                src={item.urlS3}
                style={{ maxHeight: '240px' }}
                className="img-fluid"
                alt="imagem"
              />
            ) : item.urlArquivo?.length > 0 ? (
              <img
                key={index}
                src={item.urlArquivo}
                style={{ maxHeight: '240px' }}
                className="img-fluid"
                alt="imagem"
              />
            ) : null
          )}
      </div>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
