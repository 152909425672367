import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';

import Button from 'components/Button';
import Evidencia from 'services/Evidencia';
import SemFoto from 'assets/imgs/SEMFOTO.png';

import './styles.scss';
import jsonToFormData from 'helpers/jsonBuildFormData';
import getCampoObrigatorioClassName from 'helpers/campoObrigatorio';

const SearchRede = ({ changeRede, formData, campoObrigatorio = false }) => {
  // value={formData.redeId}
  //       campoObrigatorio
  const [show, setShow] = useState(false);
  const [filtro, setFiltro] = useState('');
  const [redes, setRedes] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    window.showLoader();
    const data = await Evidencia.GetListRede(filtro);
    setRedes(data.value);
    setShow(true);
    window.hideLoader();
  };

  const changeNewRede = () => {
    if (redeSelecionada.redeId) {
      changeRede({
        ...formData,
        redeId: redeSelecionada.redeId,
        rede: redeSelecionada,
        qtdLojasTotal: redeSelecionada.qtdLojas
      });
    }
    handleClose();
  };

  const changeRedeImage = async (e) => {
    window.showLoader();
    e.preventDefault();
    if (e.target.files.length === 0) return;
    const file = e.target.files[0];

    let uploadRedeLogo = {
      evidenciaId: formData.evidenciaId,
      redeId: formData.redeId,
      file: file
    };

    const uploadRedeLogoFormData = jsonToFormData(uploadRedeLogo);
    const data = await Evidencia.PostUploadRedeLogo(uploadRedeLogoFormData);

    changeRede({
      ...formData,
      rede: { ...formData.rede, urlLogo: data.value.url ? data.value.url : formData.rede.urlLogo }
    });

    window.hideLoader();
  };

  return (
    <>
      <div className="col-6">
        <label>Rede</label>
        <div className="input-group mb-3 ">
          <input
            type="text"
            placeholder="Nome da Rede"
            className={`form-control ${
              campoObrigatorio && getCampoObrigatorioClassName(formData.redeId)
            }`}
            style={{ minHeight: '47.6px' }}
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
          />
          <button
            type="button"
            className={`btn btn-primary px-4 ${
              campoObrigatorio && getCampoObrigatorioClassName(formData.redeId)
            }`}
            onClick={handleShow}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
      <div className="col-6">
        <div className={`col-12 mb-3`}>
          {formData.rede && (
            <div className="row border border-dark p-1">
              <div className="col-4 text-center">
                <img
                  src={formData.rede?.urlLogo ? formData.rede?.urlLogo : SemFoto}
                  alt={formData.rede?.nome ? formData.rede?.nome : '-'}
                  className="w-100"
                  style={{ maxWidth: '80px' }}
                />
              </div>
              <div className="col-8 d-flex flex-column align-items-center p-1">
                <div>Trocar logo</div>
                {/* <Button onClick={() => changeRedeImage()}>Faça upload do arquivo</Button> */}
                <>
                  <label
                    htmlFor="uploadLogo"
                    className="btn btn-primary px-4"
                    style={{ borderRadius: '20px' }}>
                    Faça upload do arquivo
                    <input
                      type="file"
                      className="input-file d-none"
                      id="uploadLogo"
                      name="uploadLogo"
                      onChange={(e) => changeRedeImage(e)}
                    />
                  </label>
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Modal  */}
      <Modal show={show} onHide={handleClose} className="rede-modal">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>Selecione a rede e clique em confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row border border-1 p-2 my-1 modal-rede-list">
            {/* {renderRedes()} */}
            {redes.length
              ? redes.map((item) => {
                  return (
                    <div
                      className={`col-12 ${
                        redeSelecionada.redeId === item.redeId && 'item-selecionado'
                      }`}
                      key={item.redeId}
                      onClick={() =>
                        setRedeSelecionada(redeSelecionada.redeId !== item.redeId ? item : {})
                      }>
                      <div className="row py-2 rede-item">
                        <div className="col-4">
                          <img
                            src={item.urlLogo ? item.urlLogo : SemFoto}
                            alt={item.nome}
                            className="w-100"
                          />
                        </div>
                        <div className="col-8 d-flex justify-content-start align-items-center ps-4">
                          {item.nome}
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="ms-2 p-2 fw-semibold" width="320px" onClick={() => changeNewRede()}>
            CONFIRMAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchRede;
