import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import React from 'react';
import { Table } from 'react-bootstrap';

// import { dateFormat } from 'helpers/Date';

const Resume = ({ backTab, saveForm, formData }) => {
  console.log(formData);
  return (
    <>
      <Table striped hover>
        <tbody>
          <tr>
            <td className="col-4">
              <b>Tipo de Ativação</b>
            </td>
            <td className="col-8">Scanntech</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Rede</b>
            </td>
            <td className="col-8">{formData.rede?.nome}</td>
          </tr>
        </tbody>
      </Table>

      <table className="table table-striped table-hover mt-4">
        <h3>ATIVAÇÕES</h3>
        {formData.ativacaoScanntech.length !== 0 &&
          formData.ativacaoScanntech.map((ativacaoScanntech) => {
            return (
              <>
                <div className="ativacoes-resume mt-4">
                  <h4>
                    Ativação:<b>{ativacaoScanntech.nome}</b>
                  </h4>
                </div>
                <tbody>
                  <>
                    <tr>
                      <td className="col-4">
                        <b>Pontos de atenção</b>
                      </td>
                      <td className="col-8">{ativacaoScanntech.observacoes}</td>
                    </tr>
                    <tr>
                      <td className="col-4">
                        <b>Período</b>
                      </td>
                      <td className="col-8">
                        {ativacaoScanntech.mes}/{ativacaoScanntech.ano} -{' '}
                        {ativacaoScanntech.quinzena}° quinzena
                      </td>
                    </tr>
                  </>
                </tbody>
                <div>
                  <img
                    src={ativacaoScanntech.arquivo.urlArquivo}
                    style={{ maxHeight: '240px' }}
                    className="img-fluid"
                    alt="imagem"
                  />
                </div>
              </>
            );
          })}
      </table>
      {/* <div>
        <h4 className="pt-4 pb-2">ATIVAÇÕES:</h4>
        {formData.arquivos
          .filter((item) => item.file || item.urlArquivo?.length > 0)
          .map((item, index) =>
            item.file ? (
              <img
                key={index}
                src={URL.createObjectURL(item.file)}
                style={{ maxHeight: '240px' }}
                className="img-fluid"
                alt="imagem"
              />
            ) : item.urlArquivo?.length > 0 ? (
              <img
                key={index}
                src={item.urlArquivo}
                style={{ maxHeight: '240px' }}
                className="img-fluid"
                alt="imagem"
              />
            ) : null
          )}
      </div> */}

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
