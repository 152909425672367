import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

// import InputFile from 'components/InputFile';
import Button from 'components/Button';

import SemFoto from 'assets/imgs/SEMFOTO.png';
import QualidadeImagem from '../../assets/imgs/dicasQualidade.jpg';

import './styles.scss';
import InputUploadS3 from 'components/InputUploadS3';

const UploadImage = ({ arquivos, onChangeFile, removeFile, fullWidth = false, evidenciaId }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    window.showLoader();
    setShow(true);
    window.hideLoader();
  };

  return (
    <>
      <div className="uploadImagem">
        <h5 className={`${fullWidth ? 'text-center' : ''} my-3`}>
          {!fullWidth ? (
            <>
              <b>ESCOLHA OS ARQUIVOS</b>{' '}
              <span>
                (Atenção a qualidade de imagens! Consulte o guia{' '}
                <span className="link-primary" onClick={() => handleShow()}>
                  AQUI
                </span>
                )
              </span>
            </>
          ) : (
            <>
              <b>ESCOLHA O ARQUIVO</b>{' '}
            </>
          )}
        </h5>
        <div className="row uploadImage">
          {arquivos?.length > 0 || !fullWidth ? (
            arquivos.map((item, index) => (
              <div className={`${fullWidth ? 'px-5' : 'col-sm-6 col-md-3'} my-2`} key={index}>
                <div className="card">
                  {item.header?.length > 0 ? (
                    <h5 className="px-3 pt-2 mb-0 text-center">{item.header}</h5>
                  ) : null}
                  <div
                    className="image p-3 d-flex justify-content-center align-items-center"
                    style={{ height: '270px' }}>
                    {item.file?.name ? (
                      <img
                        src={URL.createObjectURL(item.file)}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    ) : item.urlArquivo?.length > 0 ? (
                      <img
                        src={item.urlArquivo}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    ) : item.urlS3?.length > 0 ? (
                      // Imagem de url S3 existente -----------------------------------------------------------------------------
                      <img
                        src={item.urlS3}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    ) : (
                      <img
                        src={SemFoto}
                        style={{ maxHeight: '240px' }}
                        className="img-fluid"
                        alt="imagem"
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-center flex-column p-2">
                    <InputUploadS3
                      evidenciaId={evidenciaId}
                      setItemUrl={(item) => {
                        onChangeFile(item, item.id || index);
                      }}>
                      Faça upload do arquivo
                    </InputUploadS3>
                  </div>
                  {/* <p className="text-center mb-0">{item.file?.name ? item.file.name : ''}</p> */}
                  <div className="d-flex justify-content-center pb-2">
                    <Button
                      color="link"
                      className="text-decoration-none"
                      onClick={() => removeFile(item.id)}>
                      <span className="text-danger text-decoration-none">Remover imagem</span>
                    </Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={`${fullWidth ? 'px-5' : 'col-3'} my-2`}>
              <div className="card">
                {arquivos.header?.length > 0 ? (
                  <h5 className="px-3 pt-2 mb-0 text-center">{arquivos.header}</h5>
                ) : null}
                <div
                  className="image p-3 d-flex justify-content-center align-items-center"
                  style={{ height: '270px' }}>
                  {arquivos.file?.name ? (
                    <img
                      src={URL.createObjectURL(arquivos.file)}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  ) : arquivos.urlArquivo?.length > 0 ? (
                    <img
                      src={arquivos.urlArquivo}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  ) : (
                    <img
                      src={SemFoto}
                      style={{ maxHeight: '240px' }}
                      className="img-fluid"
                      alt="imagem"
                    />
                  )}
                </div>
                <div className="d-flex justify-content-center flex-column p-2">
                  <InputUploadS3
                    evidenciaId={evidenciaId}
                    setItemUrl={(item) => {
                      onChangeFile(item);
                    }}>
                    Faça upload do arquivo
                  </InputUploadS3>
                </div>
                {/* <p className="text-center mb-0">{item.file?.name ? item.file.name : ''}</p> */}
                <div className="d-flex justify-content-center pb-2">
                  <Button
                    color="link"
                    className="text-decoration-none"
                    onClick={() => removeFile(arquivos.id)}>
                    <span className="text-danger text-decoration-none">Remover imagem</span>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Modal  */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>Instruções para garantir a qualidade das fotos de evidências:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            {/* <p className="h4 text-center">CONTEUDO</p> */}
            <img
              src={QualidadeImagem}
              // style={{ maxHeight: '240px' }}
              className="img-fluid"
              alt="QualidadeImagem"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="ms-2 p-2 fw-semibold" width="320px" onClick={() => handleClose()}>
            FECHAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadImage;
