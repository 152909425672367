import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FormBox from 'components/FormBox';
import PageTitle from 'components/PageTitle';
import Form from './form';
import EvidenciaAtivacaoScanntech from 'services/EvidenciaAtivacaoScanntech';
import Resume from './resume';
import jsonToFormData from 'helpers/jsonBuildFormData';
// import { getDateFromStringJson } from 'helpers/Date';
import Evidencia from 'services/Evidencia';

import './style.scss';
import Rascunho from 'services/Rascunho';

function Scanntech() {
  const navigate = useNavigate();
  const { id, rascunho } = useParams();

  const [activeTab, setActiveTab] = useState({
    pagina: 'form',
    subTitle: 'Preencha os dados abaixo e inclua as mídias:'
  });
  const initialFormData = {
    evidenciaId: null,
    redeId: null,
    ativacaoScanntech: []
  };
  const [formData, setFormData] = useState(initialFormData);
  const [listAtivacaoScanntech, setListAtivacaoScanntech] = useState([]);
  const [errors, setErrors] = useState([]);

  function subtractMonth(date) {
    const monthsSub = date.getDate() >= 10 ? 0 : 1;
    let d = date.getDate();
    date.setMonth(date.getMonth() - monthsSub);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    const result = (date.getMonth() + 1) % 12;
    return result ? result : 12;
  }

  useEffect(() => {
    (async () => {
      window.showLoader();
      //const date = new Date('6/10/2024');
      const date = new Date();
      const mouth = subtractMonth(date);
      const year = date.getFullYear();

      const { value: scanntechList } = await Evidencia.GetListAtivacaoScanntech({
        ano: year,
        mes: mouth
      });
      setListAtivacaoScanntech(scanntechList);

      if (!formData.evidenciaId) {
        if (id > 0) {
          if (rascunho === 'rascunho') {
            let { value } = await Rascunho.GetRascunho(id);
            value = JSON.parse(value.rascunho);
            const newAtivacaoScanntech = value.ativacaoScanntech.map((item) => {
              const scanntechItem = scanntechList.find(
                (scanntechItem) => scanntechItem.ativacaoScanntechId === item.ativacaoScanntechId
              );
              return { ...scanntechItem, ...item };
            });
            setFormData({
              ...formData,
              ...value,
              ativacaoScanntech: newAtivacaoScanntech
            });
          } else {
            const { value } = await EvidenciaAtivacaoScanntech.edit(id);
            const newAtivacaoScanntech = value.ativacaoScanntech.map((item) => {
              const scanntechItem = scanntechList.find(
                (scanntechItem) => scanntechItem.ativacaoScanntechId === item.ativacaoScanntechId
              );
              return { ...scanntechItem, ...item };
            });
            setFormData({
              ...formData,
              ...value,
              ativacaoScanntech: newAtivacaoScanntech
            });
          }
        }
        //create
        else {
          const { value } = await EvidenciaAtivacaoScanntech.create();
          setFormData({ ...formData, evidenciaId: value.evidenciaId });
        }
      }

      window.hideLoader();
    })();
  }, []);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const saveForm = async () => {
    window.showLoader();
    const formDataConvert = jsonToFormData(formData);
    const data = await EvidenciaAtivacaoScanntech.save(formDataConvert);
    if (data.value) {
      window.hideLoader();
      navigate('/sucesso');
    } else {
      window.hideLoader();
      window.scrollTo(0, 0);
      setErrors(data.errors);
    }
  };

  const renderTab = () => {
    let contentNow = <h1>Página não encontrada</h1>;
    if (activeTab.pagina === 'form') {
      contentNow = (
        <Form
          formData={formData}
          setFormData={setFormData}
          nextTab={() => changeTab({ pagina: 'resume', subTitle: 'Confira todos os dados abaixo' })}
          initialFormData={initialFormData}
          listAtivacaoScanntech={listAtivacaoScanntech}
        />
      );
    }
    if (activeTab.pagina === 'resume') {
      contentNow = (
        <Resume
          backTab={() =>
            changeTab({ pagina: 'form', subTitle: 'Preencha os dados abaixo e inclua as mídias:' })
          }
          saveForm={() => saveForm()}
          formData={formData}
          listAtivacaoScanntech={listAtivacaoScanntech}
        />
      );
    }
    return contentNow;
  };
  return (
    <div className="aticacaoScanntech">
      <PageTitle subTitle="Preencha os dados abaixo e inclua as mídias:">
        ATIVAÇÕES - SCANNTECH
      </PageTitle>
      <FormBox>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            Erro ao realizar o envio, por favor confira os dados e tente novamente.
          </div>
        )}

        <div className="row">{renderTab()}</div>
      </FormBox>
    </div>
  );
}

export default Scanntech;
