import React, { useEffect, useState } from 'react';

import FormBox from 'components/FormBox';
import PageTitle from 'components/PageTitle';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import Form from './form';
import jsonToFormData from 'helpers/jsonBuildFormData';
import EvidenciaAtivacaoLMPM from 'services/EvidenciaAtivacaoLMPM';
import Resume from './resume';
import { getDateFromStringJson } from 'helpers/Date';
import Rascunho from 'services/Rascunho';

function AcoesLmpm() {
  const navigate = useNavigate();
  const { id, rascunho } = useParams();

  const [activeTab, setActiveTab] = useState({
    pagina: 'form',
    subTitle: 'Preencha os dados abaixo e inclua as mídias:'
  });
  const [formData, setFormData] = useState({
    evidenciaId: null,
    redeId: null,
    qtdLojasTotal: null,
    qtdLojasAtivadas: null,
    tipoAcao: '',
    mecanicaAcao: '',
    marcaParceira: '',
    investimento: '',
    inicio: '',
    fim: '',
    sellInInicial: '',
    sellInFinal: '',
    sellOutInicial: '',
    sellOutFinal: '',
    resultados: '',
    rede: '',
    arquivos: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }]
  });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    (async () => {
      window.showLoader();
      if (!formData.evidenciaId) {
        //edit
        if (id > 0) {
          if (rascunho === 'rascunho') {
            let { value } = await Rascunho.GetRascunho(id);
            value = JSON.parse(value.rascunho);
            setFormData({
              ...formData,
              ...value,
              inicio: getDateFromStringJson(value.inicio),
              fim: getDateFromStringJson(value.fim),
              arquivos: formData.arquivos.map((arquivo, index) => ({
                ...arquivo,
                ...value.arquivos[index]
              }))
            });
          } else {
            const { value } = await EvidenciaAtivacaoLMPM.edit(id);
            setFormData({
              ...formData,
              ...value,
              inicio: getDateFromStringJson(value.inicio),
              fim: getDateFromStringJson(value.fim),
              arquivos: formData.arquivos.map((arquivo, index) => ({
                ...arquivo,
                ...value.arquivos[index]
              }))
            });
          }
        }
        //create
        else {
          const { value } = await EvidenciaAtivacaoLMPM.create();
          setFormData({ ...formData, evidenciaId: value.evidenciaId });
        }
      }
      window.hideLoader();
    })();
  });

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const saveForm = async () => {
    window.showLoader();
    const arquivos = formData.arquivos.filter((item) => item.file || item.urlArquivo?.length > 0);
    const jsonData = {
      ...formData,
      arquivos: arquivos
    };

    const formRequest = jsonToFormData(jsonData);
    const data = await EvidenciaAtivacaoLMPM.save(formRequest);
    if (data?.value) {
      window.hideLoader();
      navigate('/sucesso');
    } else {
      window.scrollTo(0, 0);
      setErrors(data?.errors);
      window.hideLoader();
    }
  };

  const renderTab = () => {
    let contentNow = <h1>Página não encontrada</h1>;
    if (activeTab.pagina === 'form') {
      contentNow = (
        <Form
          formData={formData}
          setFormData={setFormData}
          nextTab={() => changeTab({ pagina: 'resume', subTitle: 'Confira todos os dados abaixo' })}
        />
      );
    }
    if (activeTab.pagina === 'resume') {
      contentNow = (
        <Resume
          backTab={() =>
            changeTab({ pagina: 'form', subTitle: 'Preencha os dados abaixo e inclua as mídias:' })
          }
          saveForm={saveForm}
          formData={formData}
        />
      );
    }
    return contentNow;
  };

  return (
    <div className="aticacaoAcoesLmpm">
      <PageTitle subTitle={activeTab.subTitle}>ATIVAÇÕES - LMPM/COMBO</PageTitle>
      <FormBox>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            Erro ao realizar o envio, por favor confira os dados e tente novamente.
          </div>
        )}
        <div className="row">{renderTab()}</div>
      </FormBox>
    </div>
  );
}

export default AcoesLmpm;
