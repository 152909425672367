import React, { useState, useEffect, useRef } from 'react';

import SearchRede from 'components/SearchRede';
import Input from 'components/Input';

import TextArea from 'components/TextArea';
import UploadImage from 'components/UploadImage';
import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';

import SelectMult from 'components/SelectMult';
import TimerRascunho from 'components/TimerRascunho';
import { flushSync } from 'react-dom';
import InvestimentoJBP from 'components/InvestimentoJBP';

const Form = ({ formData, setFormData, nextTab, mpdvList }) => {
  const [errors, setErrors] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const ref = useRef();

  useEffect(() => {
    ref.current = formData;
  }, []);

  useEffect(() => {
    if (ref.current !== undefined && JSON.stringify(ref.current) !== JSON.stringify(formData)) {
      setChangeCount(changeCount + 1);
    }
  }, [formData]);

  const validacaoFormulario = async () => {
    let todosErros = [];
    // validação de campos obrigatorios
    !formData.redeId && todosErros.push('Rede');
    !formData.qtdLojasTotal && todosErros.push('Qtd. Total de Lojas');
    !formData.qtdLojasAtivadas && todosErros.push('Qtd. Lojas Ativas');
    !formData.investimento && todosErros.push('Investimento');
    !formData.resultados && todosErros.push('Observações');

    !formData.mpdvs && todosErros.push('Tipo de Mídias Indoor');

    (!formData.mpdvs?.length ||
      formData.mpdvs.filter(
        (x) => !x.arquivo || !(x.arquivo.file || x.arquivo.urlArquivo?.length > 0)
      ).length > 0) &&
      todosErros.push('Arquivos');

    setErrors(todosErros);

    if (!todosErros.length) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeFile = (file, id) => {
    window.showLoader();

    const mpdvs = formData.mpdvs.map((item, idx) => {
      if (idx === id) {
        return {
          ...item,
          arquivo: { ...item.arquivo, urlArquivo: file }
        };
      } else {
        return item;
      }
    });

    window.hideLoader();

    setFormData({ ...formData, mpdvs: mpdvs });
  };

  const removeFile = (id) => {
    window.showLoader();

    const arquivos = formData.mpdvs.map((item) => {
      if (item.materialComunicacaoId === id) {
        return {
          ...item,
          arquivo: { ...item.arquivo, urlArquivo: '' }
        };
      } else {
        return item;
      }
    });
    window.hideLoader();

    setFormData({ ...formData, mpdvs: arquivos });
  };

  let arquivos = formData.mpdvs?.map((mpdv) => ({
    id: mpdv.materialComunicacaoId,
    header: mpdv.nome,
    ...mpdv.arquivo
  }));

  return (
    <>
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((value) => {
              return (
                <p className="m-0" key={value}>
                  O campo <b>{value}</b> é obrigatório
                </p>
              );
            })}
          </div>
        )}
      </div>
      <SearchRede changeRede={setFormData} campoObrigatorio formData={formData} />

      <div className="col-6">
        <Input
          name="totalLojas"
          type="number"
          placeholder="00"
          label="Qtd. Total de Lojas"
          min={1}
          campoObrigatorio
          value={formData.qtdLojasTotal || ''}
          onChange={(e) => setFormData({ ...formData, qtdLojasTotal: e.target.value })}
        />
      </div>
      <div className="col-6">
        <Input
          name="lojasAtivas"
          type="number"
          placeholder="00"
          label="Qtd. Lojas Ativadas"
          min={1}
          campoObrigatorio
          value={formData.qtdLojasAtivadas || ''}
          onChange={(e) => setFormData({ ...formData, qtdLojasAtivadas: e.target.value })}
        />
      </div>

      <InvestimentoJBP formData={formData} setFormData={setFormData} />
      {/* <div className="row">
        <div className="col-4">
          <RadioBox
            label="A ativação está contemplada no JBP? (Faz parte de um pacote JBP?)"
            name="investimentoRadio"
            selected={formData.investimentoJBPRadio}
            options={[
              { label: 'SIM', value: true },
              { label: 'NÃO', value: false }
            ]}
            onChange={(e) => {
              e === true
                ? setFormData({ ...formData, investimento: -1, investimentoJBPRadio: e })
                : setFormData({ ...formData, investimento: 0, investimentoJBPRadio: e });
            }}
          />
        </div>
        {!formData.investimentoJBPRadio && (
          <div className="col-8">
            <InputCurrency
              value={formData.investimento}
              campoObrigatorio
              disabled={formData.investimentoJBPRadio}
              onChange={(value) => setFormData({ ...formData, investimento: value })}
              label="Investimento"
            />
          </div>
        )}
      </div> */}

      <div className="col-12">
        <TextArea
          placeholder="Descreva quaisquer observações sobre a ação em no máximo 200 caracteres."
          label="Observações"
          row="1"
          col="200"
          max={200}
          campoObrigatorio
          value={formData.resultados}
          onChange={(e) => {
            if (e.target.value === undefined || e.target.value.length <= 200) {
              setFormData({
                ...formData,
                resultados: e.target.value
              });
            } else {
              setFormData({
                ...formData,
                resultados: e.target.value.substring(0, 200)
              });
            }
          }}
        />
      </div>

      <div className="col-12">
        <SelectMult
          name="mpdvs"
          label="Mídias Indoor"
          options={mpdvList.map((item) => {
            return { value: item.materialComunicacaoId, label: item.nome };
          })}
          value={formData.mpdvs?.map((mpdv) => mpdv.materialComunicacaoId)}
          campoObrigatorio
          onChange={(values) => {
            let mpdvs = values.map((value) => {
              let material = mpdvList.find((m) => m.materialComunicacaoId === value);
              return {
                ...material,
                arquivo:
                  formData.mpdvs.find(
                    (m) => m.materialComunicacaoId === material.materialComunicacaoId
                  )?.arquivo || {}
              };
            });
            setFormData({
              ...formData,
              mpdvs: mpdvs
            });
          }}
        />
      </div>

      {arquivos && (
        <UploadImage
          evidenciaId={formData.evidenciaId}
          arquivos={arquivos}
          onChangeFile={onChangeFile}
          removeFile={removeFile}
        />
      )}
      <div className="d-flex justify-content-between pt-5">
        <BackButton />
        <SaveButton
          onClick={async () => {
            const validacao = await validacaoFormulario();
            if (validacao) {
              flushSync(() => {
                setChangeCount(0);
              });
              nextTab();
            } else {
              window.scrollTo(0, 0);
            }
          }}
        />
        <TimerRascunho
          changeCount={changeCount}
          setChangeCount={setChangeCount}
          formData={formData}
        />
      </div>
    </>
  );
};

export default Form;
