import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import { dateFormat } from 'helpers/Date';
import { numberToStringCurrency } from 'helpers/money';
import React from 'react';
import { Table } from 'react-bootstrap';

const Resume = ({ backTab, saveForm, formData, marcasList }) => {
  const marca = formData.marcasPositivadas.length
    ? formData.marcasPositivadas.map((id) => marcasList.find((x) => x.value === id)?.label)
    : '';

  // const marca = marcasList.find((item) => item.value === formData.marcasPositivadas);

  return (
    <>
      <Table striped hover>
        <tbody>
          {formData?.rede?.nome && (
            <tr>
              {' '}
              <td className="col-4">
                <b>Rede</b>
              </td>
              <td className="col-8">{formData.rede?.nome}</td>
            </tr>
          )}
          {formData.redeParceiro && (
            <tr>
              {' '}
              <td className="col-4">
                <b>Rede Parceiro</b>
              </td>
              <td className="col-8">{formData.redeParceiro}</td>
            </tr>
          )}
          {/* <tr>
            <td className="col-4">
              <b>Tipo de Evento</b>
            </td>
            <td className="col-8">{formData.eventoTipo === 'ext' ? 'Externo' : 'Interno'}</td>
          </tr> */}
          {formData?.investimento && (
            <tr>
              <td className="col-4">
                <b>Investimento</b>
              </td>
              <td className="col-8">{numberToStringCurrency(formData.investimento)}</td>
            </tr>
          )}
          {marca.length !== 0 && (
            <tr>
              <td className="col-4">
                <b>Marcas Positivadas</b>
              </td>
              <td className="col-8">{marca.join(', ')}</td>
            </tr>
          )}

          <tr>
            <td className="col-4">
              <b>Período da Ação</b>
            </td>
            <td className="col-8">
              {dateFormat(formData.inicio)} - {dateFormat(formData.fim)}{' '}
            </td>
          </tr>

          <tr>
            <td className="col-4">
              <b>Contexto do Evento</b>
            </td>
            <td className="col-8">{formData.resultados}</td>
          </tr>
        </tbody>
      </Table>

      <div>
        <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
        {formData.arquivos
          .filter((item) => item.file || item.urlArquivo?.length > 0)
          .map((item, index) =>
            item.file ? (
              <img
                key={index}
                src={URL.createObjectURL(item.file)}
                style={{ maxHeight: '240px' }}
                className="img-fluid"
                alt="imagem"
              />
            ) : item.urlArquivo?.length > 0 ? (
              <img
                key={index}
                src={item.urlArquivo}
                style={{ maxHeight: '240px' }}
                className="img-fluid"
                alt="imagem"
              />
            ) : null
          )}
      </div>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
