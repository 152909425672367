/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PageTitle from 'components/PageTitle';
import SelectInput from 'components/SelectInput';
import SelectMult from 'components/SelectMult';
import SearchRedeSimples from 'components/SearchRedeSimples';
import Button from 'components/Button';
import {
  GET_PPTX,
  GET_RELATORIO_ENVIO,
  GET_FECHAMENTO_LIST,
  GET_REGIONAL_LIST,
  GET_EVIDENCIAPILAR_LIST
} from 'services/Pptx';

import './style.scss';

function BaixarRelatorios() {
  const [errors, setErrors] = useState([]);

  const [fechamentos, setFechamentos] = useState([]);
  const [mes, setMes] = useState('');
  const [regionais, setRegionais] = useState([]);
  const [regional, setRegional] = useState('');
  const [evidenciaPilares, setEvidenciaPilares] = useState([]);
  const [evidenciaPilar, setEvidenciaPilar] = useState([]);
  const [rede, setRede] = useState({});
  const [filtroRede, setFiltroRede] = useState('');

  useEffect(() => {
    getFechamentos();
    getRegionais();
    getEvidenciaPilares();
    setRede({});
  }, []);

  async function getFechamentos() {
    let data = await GET_FECHAMENTO_LIST();
    if (data && data.value) {
      setFechamentos(data.value);
    }
  }

  async function getRegionais() {
    let data = await GET_REGIONAL_LIST();
    if (data && data.value) {
      setRegionais(data.value);
    }
  }

  async function getEvidenciaPilares() {
    let data = await GET_EVIDENCIAPILAR_LIST();
    if (data && data.value) {
      setEvidenciaPilares(data.value);
    }
  }

  async function downloadPPTX() {
    if (validate() === false) {
      return;
    }

    window.showLoader();

    let filtros = getFiltros();

    let data = await GET_PPTX(filtros);

    if (data && data.value) {

      let todayDate = getTodayDateString();

      let file = _base64ToByteArray(data.value);
      let blob = new Blob([file], { type: 'arraybuffer' });

      let fileName = 'book_plano_on_of_' + todayDate + '.pptx';
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  async function downloadPPTX_Parcial() {
    window.showLoader();

    let filtros = getFiltros();
    filtros.fechamentoMes = ''; // Para fechamento parcial

    let data = await GET_PPTX(filtros);

    if (data && data.value) {

      let todayDate = getTodayDateString();

      let file = _base64ToByteArray(data.value);
      let blob = new Blob([file], { type: 'arraybuffer' });

      let fileName = 'book_plano_on_of_' + todayDate + '.pptx';
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  async function downloadExcel() {
    if (validate() === false) {
      return;
    }

    window.showLoader();
    //showLoader(true);


    let filtros = getFiltros();

    let data = await GET_RELATORIO_ENVIO(filtros);

    if (data && data.value) {

      let todayDate = getTodayDateString();

      let file = _base64ToByteArray(data.value);
      let blob = new Blob([file], { type: 'arraybuffer' });

      let fileName = 'relatorio_envio_' + todayDate + '.xlsx';
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  function getFiltros(){
    let model = {
      regionalIds: regional,
      regionalId: regional,
      pilarIds: evidenciaPilar.join(),
      onOffRedeId: rede?.redeId ?? 0,
      fechamentoMes: mes
    };

    return model;
  }

  function validate() {
    let validationErrors = [];
    if (mes === '' || mes === null) {
      validationErrors.push('Selecione um mês de veiculação');
    }

    setErrors(validationErrors);

    if (!validationErrors.length) {
      return true;
    } else {
      return false;
    }
  }

  function _base64ToByteArray(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes;
  }

  function getTodayDateString(){
    let today = new Date();
    let date =
      today.getFullYear() +
      '_' +
      (today.getMonth() + 1) +
      '_' +
      today.getDate() +
      '_' +
      today.getHours() +
      today.getMinutes() +
      today.getSeconds();

      return date;
  }

  return (
    <div className="baixarRelatorios">
      <PageTitle subTitle="Selecione os filtros abaixo para baixar o relatório">
        Baixar Relatórios
      </PageTitle>
      <div className='row'>
      <div className="offset-md-1 col-12 col-md-10">
      <center>
        <p className="text-danger">
          Você pode subir evidências do mês até o dia 15 do mês seguinte. O book completo sempre será disponibilizados para download a partir do dia 16 de cada mês.
        </p>
      </center>
      </div>
      </div>
      <div>
        {errors && errors.length !== 0 && (
          <div className="alert alert-danger">
            {errors.map((message) => {
              return (
                <p className="m-0" key={message}>
                  {message}
                </p>
              );
            })}
          </div>
        )}
      </div>
      <form>
        <div className="row">
          {/* Regional / Mes veiculacao ------------------------------------------------------------------------------------- */}
          <div className="offset-md-1 col-12 col-md-5">
            <div className="mb-3">
              <SelectInput
                label="Regional"
                options={regionais}
                value={regional}
                onChange={(v) => setRegional(v)}
                isClearable={true}
              />
            </div>
            <div className="mb-3">
              <SelectInput
                label="Mês de Veiculação"
                options={fechamentos}
                onChange={(v) => {
                  setMes(v);
                }}
                value={mes}
                isClearable={true}
              />
            </div>
          </div>
          {/* Pilar e Rede -------------------------------------------------------------------------------------------------- */}
          <div className="col-12 col-md-5">
            <div className="mb-3 selectMult">
              <SelectMult
                label="Pilar de Ação"
                options={evidenciaPilares.map((item) => {
                  return { value: item.evidenciaPilarId, label: item.nome };
                })}
                value={evidenciaPilar}
                onChange={(values) => setEvidenciaPilar(values)}
              />
            </div>
            <div className="mb-3 searchRede">
              <SearchRedeSimples onChange={setRede} filtro={filtroRede} setFiltro={setFiltroRede} />
            </div>
          </div>
          {/* Botoes relatorio ---------------------------------------------------------------------------------------------- */}
          <div className="d-flex justify-content-center pt-2">
            <Button
              className="me-2 p-2 fw-semibold"
              width="320px"
              onClick={() => {
                downloadPPTX();
              }}>
              BAIXAR BOOK PPT
            </Button>
            <Button
              className="ms-2 p-2 fw-semibold"
              width="320px"
              onClick={() => {
                downloadExcel();
              }}>
              BAIXAR RELATÓRIO DE UPLOAD
            </Button>
            <Button
              className="ms-3 p-2 fw-semibold"
              width="320px"
              onClick={() => {
                downloadPPTX_Parcial();
              }}>
              BAIXAR BOOK PARCIAL
            </Button>
          </div>
          {/* Botoes relatorio ---------------------------------------------------------------------------------------------- */}
          <div className="d-flex justify-content-center pt-2">
            <a href="https://maisqueimagensbrfas.com.br/on-off-relatorios">Clique aqui</a> &nbsp;
            para baixar os relatórios antigos.
          </div>
        </div>
      </form>
    </div>
  );
}

export default BaixarRelatorios;
