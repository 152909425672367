import BackButton from 'components/BackButton';
import SaveButton from 'components/SaveButton';
import React from 'react';
import { Table } from 'react-bootstrap';

import { dateFormat } from 'helpers/Date';
import { numberToStringCurrency } from 'helpers/money';

const Resume = ({ backTab, saveForm, formData, tipoArquivoList }) => {
  const tipoMidia = formData.tipoMidia.length
    ? formData.tipoMidia.map((id) => tipoArquivoList.find((x) => x.value === id)?.label)
    : '';

  return (
    <>
      <Table striped hover>
        <tbody>
          <tr>
            <td className="col-4">
              <b>Tipo de Ativação</b>
            </td>
            <td className="col-8">Digital</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Loja</b>
            </td>
            <td className="col-8">{formData.loja?.nome}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Tipo de Mídia</b>
            </td>
            <td className="col-8">{tipoMidia.join(', ')}</td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Período da Ação</b>
            </td>
            <td className="col-8">
              {dateFormat(formData.inicio)} - {dateFormat(formData.fim)}{' '}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Investimento</b>
            </td>
            <td className="col-8">
              {formData.investimento === -1
                ? 'Ativação contemplada no JBP'
                : numberToStringCurrency(formData.investimento)}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <b>Aprendizados e Pontos de Melhoria</b>
            </td>
            <td className="col-8">{formData.resultados}</td>
          </tr>
        </tbody>
      </Table>

      <div>
        <h4 className="pt-4 pb-2">EVIDÊNCIAS:</h4>
        {formData.arquivos
          .filter((item) => item.file || item.urlArquivo?.length > 0)
          .map((item, index) =>
            item.file ? (
              <img
                key={index}
                src={URL.createObjectURL(item.file)}
                style={{ maxHeight: '240px' }}
                className="img-fluid"
                alt="imagem"
              />
            ) : item.urlArquivo?.length > 0 ? (
              <img
                key={index}
                src={item.urlArquivo}
                style={{ maxHeight: '240px' }}
                className="img-fluid"
                alt="imagem"
              />
            ) : null
          )}
      </div>

      <div className="d-flex justify-content-between pt-5">
        <BackButton backFunction={() => backTab()} />
        <SaveButton
          onClick={() => {
            saveForm();
          }}
        />
      </div>
    </>
  );
};

export default Resume;
